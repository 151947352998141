import styled from 'styled-components';
import bibliaBackground from '@assets/images/backgrounds/bibliaBackground.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 575px;
  padding: 25px 200px;

  background-image: url(${bibliaBackground});
  background-size: 10px auto;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: ${({ theme }) => theme.colors.white};
  font-family: Bahnschrift Regular;
  letter-spacing: 0.2rem;

  .box {
    position: relative;
    height: 300px;
    width: 500px;
    margin: 90px 50px;
    border: 10px solid ${({ theme }) => theme.colors.secondary};

    @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
      height: 400px;
      width: 200px;
      margin: 120px 100px;
    }
  }

  .box-primary {
    left: -105px;
    top: 60px;

    text-transform: uppercase;
    font-size: 55px;

    color: ${({ theme }) => theme.colors.secondary};
    z-index: 2;
  }

  h1 {
    position: absolute;
    text-transform: uppercase;
    font-size: 55px;
    left: -100px;
    top: 60px;
    z-index: 3;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 30px 100px;
    height: 775px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    padding: 30px 20px;
    height: 775px;
  }
`;
