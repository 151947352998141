import styled, { css } from 'styled-components';

interface IFormGroupProps {
  inline?: boolean;
}

export const Container = styled.div`
  max-width: 100% !important;
  height: 100%;
`;

export const Path = styled.div`
  margin-bottom: 25px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 25px;
  padding: 30px;

  border: solid 1px ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  .emailFone {
    display: flex;
  }

  .wrappperButton {
    display: flex;
    justify-content: flex-end;

    margin-top: 55px;
  }

  .wrappperHeader {
    margin-top: 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    .combobox {
      width: 400px;
      margin-right: 20px;
    }
  }
`;

export const FormGroup = styled('div')<IFormGroupProps>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  ${({ inline }) =>
    inline &&
    css`
      align-items: center;
    `}

  width: 100%;
  margin-top: ${({ inline }) => (inline ? '30px' : '15px')};

  :last-child {
    margin-left: 30px;
  }

  h2 {
    font-weight: 100;
    font-size: 17px;
    ${({ inline }) =>
      inline &&
      css`
        margin-right: 20px;
      `}
  }
`;
