import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 200px;

  h1 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 30px;
    font-weight: bold;
  }

  div {
    margin-top: 40px;
    padding-left: 40px;
    border-left: 1px solid ${({ theme }) => theme.colors.dimGray};

    h4 {
      color: ${({ theme }) => theme.colors.dimGray};
      font-size: 15px;
      font-weight: bold;
    }
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 40px 30px;
  }
`;
