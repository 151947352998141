import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Menu from '@components/site/menu';

import logoAgapeBranca from '@assets/images/logos/logoAgapeBranca.png';
import logoAgapePreta from '@assets/images/logos/logoAgapePreta.png';

import { Container, Logo } from './styles';

type HeaderProps = {
  menuType: string;
  isBlack?: boolean;
};

const Header: React.FC<HeaderProps> = ({ menuType, isBlack }: HeaderProps) => {
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(window.scrollY);
  const handleScroll = () => setScroll(window.scrollY);

  // set up listener on window to update scroll state on scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Container whiteBackground={scroll !== 0 || isBlack || openMenu}>
        <Logo onClick={() => history.push('/')}>
          <img
            src={
              scroll !== 0 || isBlack || openMenu
                ? logoAgapePreta
                : logoAgapeBranca
            }
            alt="Logo Ágape"
          />
        </Logo>
        <Menu
          menuType={menuType}
          isScroll={isBlack || scroll !== 0}
          openMenu={openMenu}
          setOpenMenu={() => setOpenMenu(!openMenu)}
        />
      </Container>
    </>
  );
};

Header.defaultProps = {
  isBlack: false,
};

export default Header;
