import React from 'react';

import check from '@assets/images/icons/check.svg';
import cancel from '@assets/images/icons/cancel.svg';
import { Block } from '../styles';
import { Container } from './styles';

type MessageProps = {
  open: boolean;
  success: boolean;
  message: string;
};

const Message: React.FC<MessageProps> = ({ open, message, success }) => {
  return (
    <Block open={open}>
      <Container>
        <img src={success ? check : cancel} alt="Status da mensagem" />
        <h2>{message}</h2>
      </Container>
    </Block>
  );
};

export default Message;
