import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  h1 {
    margin: 30px 0px;
    font-size: 30px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 80%;

    margin-bottom: 30px;

    h2 {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;

      li {
        padding: 5px 10px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    padding: 0px 100px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'auto auto auto auto';

  h4 {
    font-family: Segoe UI Regular;
    text-transform: uppercase;
    font-weight: 100;
    color: ${({ theme }) => theme.colors.black};
    padding: 15px;

    @media only screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
      display: table-caption;
    }
  }

  img {
    width: 80%;
    height: 100%;
    margin: 'auto';
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    grid-template-areas: 'auto auto';
    padding: 0px 50px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    grid-template-areas: 'auto';
    padding: 0px 15px;
  }
`;

export const LineSaparator = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin: auto;

  margin-bottom: 10px;
`;

export const Item = styled.div`
  grid-area: 'auto';

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 30px;

  img {
    width: auto;
    height: 300px;
    margin: auto;
  }

  div {
    display: flex;
    justify-content: center;

    img {
      margin: 0px 20px;
      height: 15px;
      width: auto;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;
