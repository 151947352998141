import React, { useEffect, useState } from 'react';

import HeaderPages from '@components/common/headerPages';
import Loading from '@components/common/loading';
import Button from '@components/common/button';

import { firestore } from '@services/firebaseConfig';
import { LIVROS_COLLECTION } from '@utils/firebaseCollections';

import { Livro } from '@utils/interfaces';
import { Container, Card, ImageWrapper, LivrosWrapper } from './styles';

const Library: React.FC = () => {
  const [livros, setLivros] = useState<Livro[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    firestore
      .collection(LIVROS_COLLECTION)
      .get()
      .then(data => {
        if (data.docs.length) {
          const auxLivros: Livro[] = [];
          data.forEach(doc => {
            const docData = { ...doc.data() };
            const { fileName, fileUrl, nome, description } = docData;

            const aux: Livro = {
              fileName,
              fileUrl,
              nome,
              description,
              key: doc.id,
            };
            auxLivros.push(aux);
          });
          setLivros(auxLivros);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading]);

  return (
    <Container>
      <HeaderPages title="Biblioteca" />

      <LivrosWrapper>
        {livros.map(item => (
          <Card className="img-hover-zoom img-hover-zoom--xyz">
            <ImageWrapper>
              <embed
                width="150"
                height="200"
                src={item.fileUrl}
                type="application/pdf"
              />
            </ImageWrapper>
            <div>
              <h2>{item.nome}</h2>

              <div className="wrapperButton">
                <Button onClick={() => window.open(item.fileUrl)}>
                  Baixar
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </LivrosWrapper>
    </Container>
  );
};

export default Library;
