import styled from 'styled-components';

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  padding: 30px;

  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  box-shadow: 1px 1px ${({ theme }) => theme.colors.lightGrey};

  min-width: 350px;

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    min-width: 320px;
  }
`;

export default Card;
