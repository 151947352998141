import styled from 'styled-components';
import Card from '@components/common/card';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  h3 {
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 10px;
`;

export const CardTest = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 169px;
  min-width: 23%;
  max-width: 23%;
  padding: 15px;
  margin-right: 20px;

  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.backgroundHeader};

  border: none;
  box-shadow: none;
  border-radius: 20px;

  margin-top: 15px;

  transition: 0.5s;

  .iconAndLabel {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 30px;
      font-weight: normal;
      margin-left: 10px;
    }

    img {
      fill: ${({ theme }) => theme.colors.primary};
      height: 50px;
      width: 50px;
    }
  }

  h3 {
    text-align: center;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 100;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.silver};
  }

  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    min-width: 47%;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    min-width: 100%;
  }
`;
