import React from 'react';

import { Container } from './styles';

const AboutUs: React.FC = () => {
  return (
    <Container>
      <h1>Sobre Nós</h1>
      <div>
        <h4>Igreja Batista Ágape </h4>
        <h4>Av Castelo Branco nº 176, Paraíso do Tocantins - TO.</h4>
        <h4>63 3602-4140</h4>
        <h4>igrejabatistaagape-to@hotmail.com</h4>
      </div>
    </Container>
  );
};

export default AboutUs;
