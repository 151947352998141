import React, { useState, useEffect, useRef } from 'react';

import arrowDown from '@assets/images/icons/sort-down.svg';

import { Container, Selected, Options, Option, Input } from './styles';

type ComboboxProps = {
  value: Array<any>;
  options: Array<any>;
  onChange: (value: Array<any>) => void;
  multiple?: boolean;
  arrayKey?: string;
};

const Combobox: React.FC<ComboboxProps> = ({
  value,
  options,
  arrayKey,
  multiple,
  onChange,
}) => {
  const [openOption, setOpenOptions] = useState(false);

  const handleChange = (newValue: any) => {
    onChange(newValue);
    setOpenOptions(false);
  };

  const removeItem = (item: any) => {
    if (multiple) {
      const aux = [...value];
      const index = aux.indexOf(item);
      if (index > -1) {
        aux.splice(index, 1);
      }
      handleChange(aux);
    } else {
      handleChange([]);
    }
  };

  const addItem = (item: any) => {
    if (multiple) {
      const aux = [...value];
      if (aux.includes(item)) {
        removeItem(item);
        return;
      }
      aux.push(item);
      handleChange(aux);
    } else if (value.length > 0 && item === value[0]) {
      handleChange([]);
    } else handleChange([item]);
  };

  const wrapperRef = useRef(document.createElement('div'));
  useEffect(() => {
    const handleClick = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [wrapperRef]);

  return (
    <Container ref={wrapperRef}>
      <Input onClick={() => setOpenOptions(prevState => !prevState)}>
        <Selected>
          {multiple &&
            value.map(item => (
              <div>{arrayKey ? `${item[arrayKey]}, ` : `${item}, `}</div>
            ))}
          {!multiple && value.length > 0 && (
            <div>{arrayKey ? value[0][arrayKey] : value[0]}</div>
          )}
        </Selected>
        <button type="button">
          <img src={arrowDown} alt="Seta para abrir dropdown" />
        </button>
      </Input>

      <Options open={openOption}>
        {options &&
          options.map(item => (
            <Option
              key={`options_${arrayKey ? item[arrayKey] : item}`}
              onClick={() => addItem(item)}
              include={value.includes(item)}
            >
              {arrayKey ? item[arrayKey] : item}
            </Option>
          ))}
      </Options>
    </Container>
  );
};

Combobox.defaultProps = {
  multiple: false,
  arrayKey: '',
};

export default Combobox;
