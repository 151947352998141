import React, { useState } from 'react';

import Input from '@components/common/input';
import Button from '@components/common/button';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import { Container, CardComponent } from './styles';

const ChangePass: React.FC = () => {
  const { addToast } = useToast();
  const { updatePassword, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  const OnValidators = (evt: any) => {
    evt.preventDefault();
    const firstPasswordIsValid = firstPassword.length > 6;
    const secondPasswordIsValid = firstPassword === secondPassword;
    if (firstPasswordIsValid && secondPasswordIsValid) {
      setLoading(true);
      updatePassword({ password: firstPassword });
    } else {
      setLoading(false);
      if (!firstPasswordIsValid) {
        addToast({
          type: 'error',
          title: 'Notificação!',
          description: 'Sua senha precisa ter mais de 6 digitos!',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Notificação!',
          description: 'Insira as duas senhas iguais!',
        });
      }
    }
  };

  return (
    <Container>
      <CardComponent>
        <h1>Configuração de Senha</h1>
        <h5>
          Sua senha foi configurada de forma padrão, escolha uma senha com 6 ou
          mais caracteres para acessar a plataforma.
        </h5>
        <form onSubmit={OnValidators}>
          <h4>Sua nova senha</h4>
          <Input
            id="changePass_firstPassword"
            type="password"
            value={firstPassword}
            onChange={evt => setFirstPassword(evt.target.value)}
          />
          <h4>Repita sua senha</h4>
          <Input
            type="password"
            id="changePass_secondPassword"
            value={secondPassword}
            onChange={evt => setSecondPassword(evt.target.value)}
          />
          <button type="submit">{loading ? 'CONECTANDO' : 'CONCLUIR'}</button>
          <Button
            className="tertiary"
            onClick={() => {
              signOut();
            }}
            type="button"
          >
            Voltar ao login
          </Button>
        </form>
      </CardComponent>
    </Container>
  );
};

export default ChangePass;
