import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Table from '@components/common/table';
import Loading from '@components/common/loading';
import HeaderPages from '@components/common/headerPages';

import edit from '@assets/images/icons/edit.svg';

import { CONTEUDOS_STORAGE } from '@utils/firebaseCollections';
import { firestore } from '@services/firebaseConfig';

import { Container, Path } from './styles';

const TYPES = {
  CURSO_BATISMO: 'Batismo',
  PALAVRA_CELULA: 'Palavra de célula',
  CELEBRACAO_DOMINGO: 'Palavra de celebrações',
};

const List: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [palavras, setPalavras] = useState<Array<any> | []>([]);

  useEffect(() => {
    setLoading(true);

    firestore
      .collection(CONTEUDOS_STORAGE)
      .orderBy('name')
      .get()
      .then((data: any) => {
        if (data.docs.length) {
          const aux: Array<any> = [];
          data.forEach((doc: any) => {
            const temp = { ...doc.data(), key: doc.id };
            aux.push(temp);
          });
          setPalavras(aux);
        }
        setLoading(false);
      });
  }, []);

  const getDataTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      let auxType;
      switch (item.type) {
        case 'CURSO_BATISMO':
          auxType = 'Batismo';
          break;
        case 'PALAVRA_CELULA':
          auxType = 'Palavra de célula';
          break;
        case 'CELEBRACAO_DOMINGO':
          auxType = 'Palavra de celebrações';
          break;

        default:
          break;
      }

      aux.push([
        item.name,
        auxType,
        <a href={`/conteudos/form?id=${item.key}`}>
          <img src={edit} alt="Botão de editar" />
        </a>,
      ]);
    });
    return aux;
  };

  return (
    <>
      <Container>
        <Path>Dashboard / Conteúdos</Path>
        <HeaderPages
          title="Conteúdos"
          urlBack="/dashboard"
          buttonName="Nova Conteúdo"
          buttonClick={() => history.push('/conteudos/form')}
        />

        <Loading loading={loading}>
          <Table
            data={getDataTable(palavras)}
            columns={['Nome do Arquivo', 'Tipo']}
            withAction
          />
        </Loading>
      </Container>
    </>
  );
};

export default List;
