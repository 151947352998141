import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 0px 20px;
  }
`;

export const Path = styled.div`
  margin-bottom: 25px;
`;
