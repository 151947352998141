import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@hooks/auth';

import logoCFMA from '@assets/images/logos/logoCFMA.png';
import logoAgapeBranca from '@assets/images/logos/logoAgapeBranca.png';
import { AiFillHome } from 'react-icons/ai';
import { BsBookHalf } from 'react-icons/bs';
import { SiGoogleclassroom } from 'react-icons/si';

import Hamburguer from '@components/common/hamburger';
import { ROLES } from '@utils/roles';

import {
  Header,
  HeaderMobile,
  Sidebar,
  SidebarItem,
  SidebarMobile,
  Wrapper,
  Container,
  ChildrenContainer,
} from './styles';

const Layout: React.FC = ({ children }) => {
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);
  const { user, signOut } = useAuth();
  const { pathname } = window.location;

  return (
    <>
      <Container>
        <Header onClick={() => history.push('/')}>
          <div>
            <img src={logoAgapeBranca} alt="Logo" />
          </div>
        </Header>

        <Wrapper>
          <Sidebar>
            <div>
              <div className="divider" />
              <div>
                {user.role.includes(ROLES.ADMIN.KEY) && (
                  <a href="/dashboard">
                    <SidebarItem active={pathname === '/dashboard'}>
                      <AiFillHome size={30} />
                      <span>Admin</span>
                    </SidebarItem>
                  </a>
                )}

                {user.role.includes(ROLES.STUDENT.KEY) && (
                  <a href="/class">
                    <SidebarItem active={pathname === '/class'}>
                      <SiGoogleclassroom size={30} />
                      <span>Área do Aluno</span>
                    </SidebarItem>
                  </a>
                )}

                <a href="/library">
                  <SidebarItem active={pathname === '/library'}>
                    <BsBookHalf size={30} />
                    <span>Biblioteca</span>
                  </SidebarItem>
                </a>
              </div>
            </div>

            <div className="wrapperButton">
              <div className="divider" />
              <button type="button" onClick={signOut}>
                SAIR
              </button>
            </div>
          </Sidebar>

          <HeaderMobile>
            <img src={logoCFMA} alt="Logo" />
            <Hamburguer
              isScroll
              openMenu={openMenu}
              setOpenMenu={() => setOpenMenu(!openMenu)}
            />
          </HeaderMobile>

          {openMenu && (
            <SidebarMobile>
              {user.role.includes(ROLES.ADMIN.KEY) && (
                <a href="/dashboard">Admin</a>
              )}

              {user.role.includes(ROLES.STUDENT.KEY) && (
                <a href="/class">Área do Aluno</a>
              )}

              <a href="/library">Biblioteca</a>
            </SidebarMobile>
          )}

          <ChildrenContainer>{children}</ChildrenContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default Layout;
