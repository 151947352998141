import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 45px;

  background-color: white;
  border-radius: 10px;

  img {
    height: 80px;
  }

  h2 {
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.black};
  }
`;
