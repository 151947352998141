import React, { useState } from 'react';
import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import Input from '@components/common/input';
import Card from '@components/common/card';
import Button from '@components/common/button';

import { Container } from './styles';

const SignIn: React.FC = () => {
  const { signIn, resetPassword } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isReset, setIsReset] = useState(false);

  const OnValidators = (evt: any) => {
    setLoading(true);
    evt.preventDefault();
    const emailIsValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    const passwordIsValid = password !== '';
    if (emailIsValid && passwordIsValid) {
      signIn({ login: email, password })
        .then(response => {
          console.log('Usuario autenticado');
        })
        .catch(error => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Notificação!',
            description: 'Usuário ou senha inválidos!',
          });
        });
    } else {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Notificação!',
        description: 'Usuário ou senha inválidos!',
      });
    }
  };

  const handleReset = (evt: any) => {
    setLoading(true);
    evt.preventDefault();
    const emailIsValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    if (emailIsValid) {
      resetPassword({ email })
        .then(() => {
          addToast({
            type: 'info',
            title: 'Notificação!',
            description: 'Foi enviado um e-mail para trocar sua senha!',
          });
          setIsReset(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Notificação!',
            description: 'Erro ao enviar e-mail para reset de senha!',
          });
        });
    } else {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Notificação!',
        description: 'Erro ao enviar e-mail para reset de senha!',
      });
    }
  };

  return (
    <Container>
      <Card>
        {!isReset && (
          <>
            <h1>Login</h1>
            <form onSubmit={OnValidators}>
              <h4>Seu e-mail</h4>
              <Input
                type="email"
                id="signIn_email"
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />
              <h4>Sua senha</h4>
              <Input
                type="password"
                id="signIn_password"
                value={password}
                onChange={evt => setPassword(evt.target.value)}
              />
              <button type="submit">{loading ? 'CONECTANDO' : 'ENTRAR'}</button>
              <Button
                className="tertiary"
                onClick={() => setIsReset(true)}
                type="button"
              >
                Esqueceu sua senha?
              </Button>
            </form>
          </>
        )}

        {isReset && (
          <>
            <h1>Solicitar nova senha</h1>
            <form onSubmit={handleReset}>
              <h4>Seu e-mail</h4>
              <Input
                type="email"
                id="signIn_email"
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />

              <button type="submit">{loading ? 'CONECTANDO' : 'ENVIAR'}</button>
              <Button
                className="tertiary"
                onClick={() => setIsReset(false)}
                type="button"
              >
                Voltar ao login
              </Button>
            </form>
          </>
        )}
      </Card>
    </Container>
  );
};

export default SignIn;
