import React from 'react';

import logoAcampKids from '@assets/images/logos/logoAcampKids.png';
import logoAcampRDL from '@assets/images/logos/logoAcampRDL.png';
import logoFaceFace from '@assets/images/logos/logoFaceFace.png';

import { Container } from './styles';

const Event: React.FC = () => {
  return (
    <Container>
      <h1>Inscrições para eventos</h1>
      <div>
        <img src={logoAcampRDL} alt="Acamp RDL" />
        <img src={logoAcampKids} alt="Acamp kids" />
        <img src={logoFaceFace} alt="Encontro Face a Face" />
      </div>
    </Container>
  );
};

export default Event;
