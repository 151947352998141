import styled from 'styled-components';
import fundoSpotfy from '@assets/images/backgrounds/fundoSpotfy.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px 10px;

  background-image: url(${fundoSpotfy});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: ${({ theme }) => theme.colors.white};

  h1 {
    font-size: 30px;
  }

  h3 {
    margin: 30px 0px;
    font-size: 25px;
    letter-spacing: 3px;
    text-align: center;
  }

  div {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    width: 100%;

    img {
      height: 100px;
      width: auto;
      margin: 20px;
    }

    @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
      padding: 0px;
      flex-direction: column;
    }
  }
`;
