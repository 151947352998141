import styled from 'styled-components';
import Card from '@components/common/card';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Path = styled.div`
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 30px;

  h2 {
    margin-right: 15px;
  }

  div {
    min-width: 200px;
  }
`;

export const ContainerMaterias = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 25px;
`;

export const CardTest = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 169px;
  min-width: 23%;
  max-width: 23%;
  padding: 15px;
  margin-right: 20px;

  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.backgroundHeader};

  border: none;
  box-shadow: none;
  border-radius: 20px;

  margin-top: 15px;

  transition: 0.5s;

  .iconAndLabel {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 30px;
      font-weight: normal;

      text-align: center;
    }

    img {
      fill: ${({ theme }) => theme.colors.primary};
      height: 50px;
      width: 50px;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.silver};
  }

  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    min-width: 47%;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    min-width: 100%;
  }
`;
