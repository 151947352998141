import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import ChangePass from '@pages/Auth/ChangePass';
import Layout from '@components/cfma-portal/layout';

import { useAuth } from '@hooks/auth';
import { ROLES } from '@utils/roles';

import { IRouteProps } from './interfaces';

const Route: React.FC<IRouteProps> = ({
  isAdmin = false,
  isPrivate = false,
  isAuthentication = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  if (!user && isPrivate) {
    return <Redirect to="/sign-in" />;
  }

  if (isPrivate && user && user.senhaPadrao) {
    return <ChangePass />;
  }

  if (isAuthentication && user) {
    return (
      <Layout>
        <Redirect to="/dashboard" />
      </Layout>
    );
  }

  if (user && isPrivate) {
    if (isAdmin && !user.role.includes(ROLES.ADMIN.KEY)) {
      return (
        <Layout>
          <Redirect to="/class" />
        </Layout>
      );
    }

    return (
      <Layout>
        <Component />
      </Layout>
    );
  }

  return <ReactDOMRoute {...rest} render={() => <Component />} />;
};

export default Route;
