import React from 'react';

import logoFacePreto from '@assets/images/logoFacePreto.png';
import logoInstaPreto from '@assets/images/logoInstaPreto.png';
import PraIria from '@assets/images/pastores/PraIria.png';
import PrGean from '@assets/images/pastores/PrGean.png';
import PrHugo from '@assets/images/pastores/PrHugo.png';
import PrPaulo from '@assets/images/pastores/PrPaulo.png';

import { Grid, Container, LineSaparator, Item } from './styles';

const pastors = [
  {
    nome: 'Gean Carlos',
    facebook: 'https://www.facebook.com/Pr.Gean',
    instagram: 'https://www.instagram.com/prgeancarloss/',
    urlFoto: PrGean,
  },
  {
    nome: 'Íria Vale',
    facebook: 'https://www.facebook.com/iriaagape',
    instagram: 'https://www.instagram.com/iriavsouza/',
    urlFoto: PraIria,
  },
  {
    nome: 'Hugo Correia',
    facebook: 'https://www.facebook.com/hugo.correia.96558',
    instagram: 'https://www.instagram.com/hugocorreiapsi/',
    urlFoto: PrHugo,
  },
  {
    nome: 'Paulo Azevedo',
    facebook: 'https://www.facebook.com/pauloernandesborgesdeazevedo.azevedo',
    instagram: 'https://www.instagram.com/pauloazevedo1969/',
    urlFoto: PrPaulo,
  },
];

const Pastors: React.FC = () => {
  const onClickRedesSociais = (url: string) => {
    window.open(url, 'Teste');
  };

  return (
    <Container>
      <h1>Pastores</h1>
      <div>
        <h2>Ágape Paraíso do Tocantins - TO</h2>
        <ul>
          <li>Pr. Gean Carlos de Souza</li>
          <li>Pra. Iria Vale de Souza</li>
          <li>Pr. Paulo Ernandes de Azevedo</li>
          <li>Pr. Rodrigo Nascimento Lacerda Guimarães</li>
        </ul>
      </div>

      <div>
        <h2>Ágape Miracema - TO</h2>
        <ul>
          <li>Pr. Joelson Barreira de Andrade</li>
          <li>Pra. Patrícia Luciana Barbosa Barreira</li>
        </ul>
      </div>

      <div>
        <h2>Ágape Queimados - RJ</h2>
        <ul>
          <li>Pr. Jediel Santos da Silva </li>
          <li>Pra. Roselane Ferreira Pereira da Silva</li>
        </ul>
      </div>

      <div>
        <h2>Ágape Catalão - GO</h2>
        <ul>
          <li>Pr. Herique Neto do Nascimento </li>
          <li>Pra. Katia Rose de Mesquita</li>
        </ul>
      </div>

      <div>
        <h2>Ágape Itumbiara - GO</h2>
        <ul>
          <li>Pra. Márcia Daniela dos Santos </li>
          <li>Pr. Cristiano Cabral da Costa</li>
        </ul>
      </div>
      {/* <Grid>
        {pastors.map(item => (
          <Item key={`ministerys_${item.nome}`}>
            <img src={item.urlFoto} alt="Foto Pastor" />
            <h4>{item.nome}</h4>

            <LineSaparator />
            <div>
              <img src={logoFacePreto} alt="Logo do Facebook" />
              <img src={logoInstaPreto} alt="Logo do Instagram" />
            </div>
          </Item>
        ))}
      </Grid> */}
    </Container>
  );
};

export default Pastors;
