import React from 'react';

import Hamburguer from '@components/common/hamburger';

import {
  Container,
  ContainerMobile,
  Item,
  MobileMenu,
  MobileItem,
} from './styles';

type MenuProps = {
  menuType: string;
  isScroll: boolean;
  openMenu: boolean;
  setOpenMenu: () => void;
};

const NAV_ITEMS = [
  { menuType: 'HOME_PAGE', title: 'Home', url: '/' },
  { menuType: 'SOBRE', title: 'Sobre', url: '/sobre' },
  {
    menuType: 'MINISTERIOS_CELULAS',
    title: 'Pequenos Grupos',
    url: '/ministerios/celulas',
  },
  { menuType: 'CONTEUDOS', title: 'Conteúdos', url: '/conteudos-page' },
  {
    menuType: 'AO_VIVO',
    title: 'Assista Online',
    url: 'https://www.youtube.com/c/IgrejaBatista%C3%81gape-PSO',
  },
  { menuType: 'PORTAL_CFMA', title: 'Portal CFMA', url: '/dashboard' },
];

const Menu: React.FC<MenuProps> = ({
  menuType,
  isScroll,
  openMenu,
  setOpenMenu,
}: MenuProps) => {
  return (
    <>
      <Container whiteBackground={isScroll || openMenu}>
        {NAV_ITEMS.map(item => (
          <a href={item.url}>
            <Item
              whiteBackground={isScroll || openMenu}
              active={menuType === item.menuType}
            >
              {item.title}
            </Item>
          </a>
        ))}
      </Container>

      <ContainerMobile>
        <Hamburguer
          isScroll={isScroll || openMenu}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />
      </ContainerMobile>

      {openMenu && (
        <MobileMenu>
          {NAV_ITEMS.map(item => (
            <a href={item.url}>
              <MobileItem>{item.title}</MobileItem>
            </a>
          ))}
        </MobileMenu>
      )}
    </>
  );
};

export default Menu;
