import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.secondary};

  div {
    display: flex;
    align-items: center;
  }

  img {
    height: 30px;
    width: auto;

    margin-right: 10px;
    cursor: pointer;
  }

  h1 {
    font-weight: 100;
  }
`;
