import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding-top: 64px;

  .footer {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const Wrapper = styled.div`
  background-color: #f1f1f1;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  column-gap: 40px;

  padding: 0px 180px;
  padding-top: 100px;

  h1 {
    padding: 0px 15px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 30%;

  margin-bottom: 30px;
  padding: 10px 30px;

  text-align: center;

  border-radius: 4px;
  border: #dadada 1px solid;

  background-color: white;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 30px;

    height: 180px;
    width: 180px;

    border-radius: 50%;
    border: #dadada 5px solid;

    img {
      height: 100px;
    }
  }

  h1 {
    margin: 20px 0px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    width: 100%;
  }
`;
