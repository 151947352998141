import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */

  padding: 40px 250px;

  background-color: #c7edf6;
  color: ${({ theme }) => theme.colors.secondary};

  animation: 0.2s;

  > h1 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  > div {
    position: relative;
    margin: 30px 0px;

    > div {
      display: flex;
      justify-content: space-between;

      .contas {
        column-gap: 30px;
        display: flex;

        @media only screen and (max-width: ${({ theme }) =>
            theme.sizes.tablet}) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      h3 {
        margin: 20px 0px;
        letter-spacing: 2px;
      }

      .logoPix {
        position: absolute;

        top: -45px;
        right: 0px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 230px;
        height: 230px;

        background-color: white;
        border-radius: 50%;

        img {
          height: 50px;
          width: 150px;
          margin-bottom: 10px;
        }

        h3 {
          margin: 3px 0px;
        }

        @media only screen and (max-width: ${({ theme }) =>
            theme.sizes.tablet}) {
          position: relative;
          top: 0px;
          right: 0px;
        }
      }

      @media only screen and (max-width: ${({ theme }) => theme.sizes.mobile}) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 30px;

        text-align: center;

        h2 {
          text-align: center;
        }
      }
    }
  }

  h3 {
    margin: 30px;
    letter-spacing: 4px;
    font-weight: normal;
  }

  button {
    padding: 15px 30px;
    border-radius: 15px;
    color: ${({ theme }) => theme.colors.tertiary};
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    font-weight: bold;
    font-size: 15px;

    :hover {
      opacity: 0.9;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 30px 20px;
  }
`;
