import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@hooks/auth';

import Combobox from '@components/common/combobox';
import HeaderPages from '@components/common/headerPages';

import {
  CURSO_COLLECTION,
  USERS_COLLECTION,
  CURSO_PERIODOS_COLLECTION,
  CURSO_MATERIAS_COLLECTION,
} from '@utils/firebaseCollections';
import { PeriodoAluno } from '@utils/interfaces';

import { getItemById } from '@services/commons';
import { firestore } from '@services/firebaseConfig';

import {
  Container,
  Path,
  Wrapper,
  ContainerMaterias,
  CardTest,
} from './styles';

const Dash: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ID = query.get('id');

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [materiasData, setMateriasData] = useState<Array<any> | []>([]);
  const [periodosData, setPeriodosData] = useState<Array<any> | []>([]);
  const [meusPeriodos, setMeusPeriodos] = useState<Array<PeriodoAluno> | []>(
    [],
  );
  const [periodoSelect, setPeriodoSelect] = useState<Array<any> | []>([]);

  useEffect(() => {
    if (ID) {
      setLoading(true);
      getItemById(CURSO_COLLECTION, ID).then((data: any) => {
        const { nome, reprovavel } = data.data();
        setName(nome);
      });

      firestore
        .collection(USERS_COLLECTION)
        .doc(user.uid)
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_PERIODOS_COLLECTION)
        .get()
        .then(data => {
          if (data.docs.length) {
            const aux: Array<any> = [];
            data.forEach((doc: any) => {
              const temp = { ...doc.data(), key: doc.id };
              aux.push(temp);
            });
            setMeusPeriodos(aux);
          }
        });

      firestore
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_PERIODOS_COLLECTION)
        .get()
        .then(data => {
          if (data.docs.length) {
            const aux: Array<any> = [];
            data.forEach((doc: any) => {
              const temp = { ...doc.data(), key: doc.id };
              aux.push(temp);
            });
            setPeriodosData(aux);
          }
        });

      firestore
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_MATERIAS_COLLECTION)
        .get()
        .then(data => {
          if (data.docs.length) {
            const aux: Array<any> = [];
            data.forEach((doc: any) => {
              const temp = { ...doc.data(), key: doc.id };
              aux.push(temp);
            });
            setMateriasData(aux);
          }
        });
    }
  }, []);

  const renderMaterias = () => {
    const aux: Array<any> = [];
    if (periodoSelect.length > 0) {
      const periodo = { ...periodoSelect[0] };
      const auxSelected = meusPeriodos.filter(item => item.key === periodo.key);
      const selected: PeriodoAluno | undefined =
        auxSelected.length > 0 ? auxSelected[0] : undefined;

      if (selected) {
        materiasData.forEach(element => {
          const temp = selected.materias.filter(
            (item: any) => item.id === element.key,
          );
          if (temp.length > 0) {
            aux.push(
              <CardTest
                key={element.key}
                onClick={() => {
                  history.push(
                    `/aulas/visualizacao?id=${element.key}&cursoId=${ID}&nome=${element.nome}`,
                  );
                }}
              >
                <div className="iconAndLabel">
                  <h1>{element.nome}</h1>
                </div>
              </CardTest>,
            );
          }
        });
      }
    }

    return aux;
  };

  return (
    <>
      <Container>
        <Path>{`Área do Aluno / Curso / ${name}`}</Path>
        <HeaderPages title={`${name}`} urlBack="/class" />

        <Wrapper>
          <h2>Período: </h2>
          <div>
            <Combobox
              value={periodoSelect}
              options={periodosData}
              arrayKey="nome"
              onChange={newValue => setPeriodoSelect(newValue)}
            />
          </div>
        </Wrapper>

        <ContainerMaterias>{renderMaterias()}</ContainerMaterias>
      </Container>
    </>
  );
};

export default Dash;
