import React from 'react';

import { HamburguerLine, Container } from './styles';

type HamburguerProps = {
  isScroll: boolean;
  openMenu: boolean;
  setOpenMenu: () => void;
};

const Hamburguer: React.FC<HamburguerProps> = ({
  isScroll,
  openMenu,
  setOpenMenu,
}: HamburguerProps) => {
  return (
    <Container onClick={setOpenMenu}>
      <HamburguerLine
        className="rotateTop"
        whiteBackground={isScroll || openMenu}
        activeMobileMenu={openMenu}
      />

      <HamburguerLine
        className="rotateMiddle"
        whiteBackground={isScroll || openMenu}
        style={{ marginTop: '8px' }}
        activeMobileMenu={openMenu}
      />

      <HamburguerLine
        className="rotateBottom"
        whiteBackground={isScroll || openMenu}
        style={{ marginTop: '8px' }}
        activeMobileMenu={openMenu}
      />
    </Container>
  );
};

export default Hamburguer;
