import React from 'react';

import Hero from '@components/site/hero';
import Wellcome from '@components/site/welcome';
import Ministery from '@components/site/ministery';
import Content from '@components/site/content';
import Event from '@components/site/event';
import Donate from '@components/site/donate';
import AboutUs from '@components/site/about-us';

import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Container>
      <Hero type="HOME_PAGE" />
      <Wellcome />
      <Ministery />
      {/* <Pastors /> */}
      <Content />
      <Event />
      <Donate />
      <AboutUs />
    </Container>
  );
};

export default Home;
