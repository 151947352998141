import React from 'react';

import { Container } from './styles';

type SwitchProps = {
  checked: boolean;
  onChange: () => void;
};

const Switch: React.FC<SwitchProps> = ({ checked, onChange }) => {
  return (
    <Container>
      <input type="checkbox" checked={checked} onClick={onChange} />
      <span className="slider round" />
    </Container>
  );
};

export default Switch;
