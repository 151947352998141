import React from 'react';
import { useHistory } from 'react-router-dom';

import HeaderPages from '@components/common/headerPages';

import userIcon from '@assets/images/icons/user.svg';
import libraryIcon from '@assets/images/icons/library.svg';
import turmasIcon from '@assets/images/icons/turmas.svg';
import webclassIcon from '@assets/images/icons/webclass.svg';

import { Container, WrapperCards, CardTest } from './styles';

const Dashboard: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <HeaderPages title="Dashboard" />

      <WrapperCards>
        <CardTest onClick={() => history.push('/usuario')}>
          <div className="iconAndLabel">
            <img src={userIcon} alt="Icone do usuário" />
            <h1>Usuários</h1>
          </div>
          <h3>Controle de membros, alunos, pastores e professores</h3>
        </CardTest>

        <CardTest onClick={() => history.push('/curso')}>
          <div className="iconAndLabel">
            <img src={turmasIcon} alt="Icone de Cursos" />
            <h1>Cursos</h1>
          </div>
          <h3>Controle de cursos do CFMA</h3>
        </CardTest>

        <CardTest onClick={() => history.push('/aulas')}>
          <div className="iconAndLabel">
            <img src={webclassIcon} alt="Icone de Aulas" />
            <h1>Aulas</h1>
          </div>
          <h3>Controle de cadastros de aulas</h3>
        </CardTest>

        <CardTest onClick={() => history.push('/conteudos')}>
          <div className="iconAndLabel">
            <img src={libraryIcon} alt="Conteúdos" />
            <h1>Conteúdos</h1>
          </div>
          <h3>Controle de todos os conteúdos em pdf</h3>
        </CardTest>

        <CardTest onClick={() => history.push('/library/list')}>
          <div className="iconAndLabel">
            <img src={libraryIcon} alt="Icone da biblioteca" />
            <h1>Biblioteca</h1>
          </div>
          <h3>Controle de livros e artigo em pdf</h3>
        </CardTest>
      </WrapperCards>
    </Container>
  );
};

export default Dashboard;
