import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderPages from '@components/common/headerPages';
import Loading from '@components/common/loading';

import { AULAS_COLLECTION } from '@utils/firebaseCollections';
import { PeriodoAluno } from '@utils/interfaces';

import { firestore } from '@services/firebaseConfig';

import {
  Container,
  Path,
  Wrapper,
  Video,
  AulasList,
  LabelAula,
} from './styles';

const Visualizacao: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ID = query.get('id');
  const CURSO_ID = query.get('cursoId');
  const AULA_NOME = query.get('nome');

  const [loading, setLoading] = useState(false);
  const [aulas, setAulas] = useState<Array<any> | []>([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (ID) {
      setLoading(true);

      firestore
        .collection(AULAS_COLLECTION)
        .where('materia_id', '==', ID)
        .get()
        .then((data: any) => {
          if (data.docs.length) {
            const aux: Array<any> = [];
            data.forEach((doc: any) => {
              const temp = { ...doc.data(), key: doc.id };
              aux.push(temp);
            });

            aux.sort((a, b) => a.ordem - b.ordem);
            setAulas(aux);
          }
          setLoading(false);
        });
    }
  }, []);

  const renderVideo = () => {
    const video = aulas.filter(item => item.key === selected);

    if (video.length > 0)
      return (
        <iframe
          width="100%"
          height="450"
          frameBorder="0"
          src={video[0].link}
          title={video[0].ordem}
        />
      );

    return <div>Selecione uma Aula</div>;
  };

  const renderAulas = () => {
    const aux: Array<any> = [];
    aulas.forEach(aula => {
      aux.push(
        <LabelAula
          className={`${aula.key === selected && 'selected'}`}
          onClick={() => {
            setSelected(aula.key);
          }}
        >
          {`Aula ${aula.ordem}`}
        </LabelAula>,
      );
    });
    return aux.length > 0 ? aux : 'Sem Aulas';
  };

  return (
    <>
      <Container>
        <Path>{`Área do Aluno / Curso / Aulas / ${AULA_NOME}`}</Path>
        <HeaderPages
          title={AULA_NOME || ''}
          urlBack={`/curso/dash?id=${CURSO_ID}`}
        />

        <Loading loading={loading}>
          <Wrapper>
            <Video>{renderVideo()}</Video>
            <AulasList>
              AULAS
              <div>{renderAulas()}</div>
            </AulasList>
          </Wrapper>
        </Loading>
      </Container>
    </>
  );
};

export default Visualizacao;
