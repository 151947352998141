import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h4 {
      margin-top: 20px;
    }

    button {
      margin-top: 40px;
      height: 40px;
      border-radius: 25px;
      border: none;

      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;

      background-color: ${({ theme }) => theme.colors.primary};

      :hover {
        opacity: 0.95;
      }

      &.tertiary {
        margin-top: 10px;
      }
    }
  }
`;
