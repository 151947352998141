import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 20px 0px;

  table {
    width: 100%;
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.colors.secondary};

    tr {
      border: 1px solid ${({ theme }) => theme.colors.secondary};
      th,
      td {
        text-align: start;

        height: 40px;
        padding: 0px 10px;

        img {
          height: 20px;
          width: auto;
          cursor: pointer;
        }
      }

      th {
        background-color: ${({ theme }) => theme.colors.backgroundHeader};
      }
    }
  }
`;
