import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'auto auto auto';

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    grid-template-areas: 'auto auto';
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    display: block;
  }

  .carousel-root {
    .selected {
      width: 18px;
      border-radius: 10px;
    }
  }
`;

export const Item = styled.div`
  position: relative;
  grid-area: 'auto';
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const ContainerHover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: ${({ theme }) => theme.colors.secondary};

  ${Item}:hover & {
    opacity: 0.9;
    cursor: pointer;
  }

  h1 {
    color: white;
    font-size: 30px;
    font-family: Segoe UI Regular;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;
