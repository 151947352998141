import styled from 'styled-components';

interface ISidebarItemProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;

  min-height: 100vh;
  min-width: 100vw;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;

  display: flex;

  width: 100%;
  height: ${({ theme }) => theme.sizes.headerHeight};

  background-color: ${({ theme }) => theme.colors.backgroundHeader};

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 280px;
    height: ${({ theme }) => theme.sizes.headerHeight};
    background-color: ${({ theme }) => theme.colors.primary};

    img {
      height: 70%;
      width: auto;
      cursor: pointer;
    }
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: none;
  }

  z-index: 2000;
`;

export const HeaderMobile = styled.div`
  display: none;
  position: relative;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: ${({ theme }) => theme.sizes.headerHeight};
    padding: 5px 20px;

    img {
      height: ${({ theme }) => theme.sizes.headerHeight};
      width: auto;
    }
  }
`;

export const SidebarMobile = styled.div`
  position: absolute;
  display: none;
  top: 80px;

  width: 100%;
  padding: 10px 20px;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: flex;
    flex-direction: column;

    background-color: #fff;
    border-bottom: 1px solid black;
  }

  z-index: 999999;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;

  height: 100%;
  width: 100%;

  padding-top: ${({ theme }) => theme.sizes.headerHeight};

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 0px 5px;
    flex-direction: column;

    a {
      padding-top: 10px;
      color: black;
      font-size: 30px;
      text-decoration: none;
    }
  }
`;

export const Sidebar = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.sizes.headerHeight};
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.secondary};

  width: 280px !important;
  min-width: 280px !important;

  .divider {
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .section {
    margin-bottom: 10px;
    font-size: 15px;
  }

  div {
    width: 100%;

    a {
      color: black;
      text-decoration: none;
    }
  }

  button {
    height: 60px;
    width: 150px;

    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;

    border-radius: 25px;
    border: none;

    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }

  .wrapperButton {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: none;
  }
`;

export const SidebarItem = styled.div<ISidebarItemProps>`
  display: flex;
  align-items: center;

  font-size: 22px;
  padding: 15px;

  color: white;
  opacity: 0.5;

  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  ${({ active }) =>
    active &&
    `
    background-color: #1E3A4D;
    opacity: 1;
    `}

  span {
    margin-left: 20px;
  }

  :hover {
    cursor: pointer;
    background-color: #1e3a4d;
    opacity: 0.65;
  }
`;

export const ChildrenContainer = styled.div`
  height: 100%;
  width: 100%;

  padding: 30px 40px;
  padding-left: 320px;

  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 30px 10px;
    flex-direction: column;
  }
`;
