import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Input from '@components/common/input';
import Table from '@components/common/table';
import Button from '@components/common/button';
import Switch from '@components/common/switch';
import Loading from '@components/common/loading';
import Combobox from '@components/common/combobox';
import HeaderPages from '@components/common/headerPages';
import { Message } from '@components/common/modals';

import {
  CURSO_COLLECTION,
  CURSO_ALUNOS_COLLECTION,
  CURSO_MATERIAS_COLLECTION,
  USERS_COLLECTION,
  CURSO_PERIODOS_COLLECTION,
} from '@utils/firebaseCollections';
import { getItemById, getCollectionByItem } from '@services/commons';

import { firestore } from '@services/firebaseConfig';
import { Container, Path, FormContainer, FormGroup } from './styles';

const Form: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ID = query.get('id');

  const [loadingNewUser, setLoadingNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('Usuário cadastrado com sucesso');
  const [successMessage, setSuccessMessage] = useState(false);

  const [name, setName] = useState('');
  const [checked, setChecked] = useState(false);
  const [materias, setMaterias] = useState<Array<any> | []>([]);
  const [periodos, setPeriodos] = useState<Array<any> | []>([]);
  const [usuarios, setUsuarios] = useState<Array<any> | []>([]);
  const [alunos, setAlunos] = useState<Array<any> | []>([]);
  const [newUser, setNewUser] = useState<Array<any> | []>([]);

  const getAlunos = () => {
    if (ID) {
      setLoadingNewUser(true);
      firestore
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_ALUNOS_COLLECTION)
        .get()
        .then((data: any) => {
          const map: Array<any> = [];
          if (data.docs.length) {
            data.forEach((doc: any) => {
              const aux = { ...doc.data(), key: doc.id };
              map.push(aux);
            });
            setAlunos(map);
          }
          setLoadingNewUser(false);
        })
        .catch(() => setLoadingNewUser(false));
    }
  };

  useEffect(() => {
    if (ID) {
      setLoading(true);
      getItemById(CURSO_COLLECTION, ID).then((data: any) => {
        const { nome, reprovavel } = data.data();
        setName(nome);
        setChecked(reprovavel);
      });

      getCollectionByItem(CURSO_COLLECTION, ID, CURSO_MATERIAS_COLLECTION).then(
        (data: any) => {
          const map: Array<any> = [];
          if (data.docs.length) {
            data.forEach((doc: any) => {
              const aux = { ...doc.data(), key: doc.id };
              map.push(aux);
            });
            setMaterias(map);
          }
          setLoading(false);
        },
      );

      firestore
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_PERIODOS_COLLECTION)
        .where('concluido', '==', false)
        .get()
        .then((data: any) => {
          const map: Array<any> = [];
          if (data.docs.length) {
            data.forEach((doc: any) => {
              const aux = { ...doc.data(), key: doc.id };
              map.push(aux);
            });
            setPeriodos(map);
          }
        });

      firestore
        .collection(USERS_COLLECTION)
        .where('role', 'array-contains', 'student')
        .get()
        .then((data: any) => {
          const map: Array<any> = [];
          if (data.docs.length) {
            data.forEach((doc: any) => {
              const aux = { ...doc.data(), key: doc.id };
              map.push(aux);
            });
            setUsuarios(map);
          }
        });

      getAlunos();
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);
  };

  const getDataTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      aux.push([item.nome, item.horas]);
    });
    return aux;
  };

  const getDataAlunosTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      aux.push([item.nome]);
    });
    return aux;
  };

  const setUser = () => {
    if (newUser.length > 0 && ID) {
      setLoadingNewUser(true);
      setNewUser([]);

      const user = newUser[0];
      const periodoKey = periodos.length > 0 ? periodos[0].key : '';
      const materiasData: any = [];
      materias.forEach(item => {
        if (item.primeiro_periodo)
          materiasData.push({ aprovado: false, id: item.key });
      });

      firestore
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .collection(CURSO_ALUNOS_COLLECTION)
        .doc(user.key)
        .set({
          nome: user.name,
          usuario: firestore.collection(USERS_COLLECTION).doc(user.key),
        });

      firestore
        .collection(USERS_COLLECTION)
        .doc(user.key)
        .collection(CURSO_COLLECTION)
        .doc(ID)
        .set({
          concluido: false,
          data_inicio: new Date(),
        })
        .then(() => {
          firestore
            .collection(USERS_COLLECTION)
            .doc(user.key)
            .collection(CURSO_COLLECTION)
            .doc(ID)
            .collection(CURSO_PERIODOS_COLLECTION)
            .doc(periodoKey)
            .set({ materias: materiasData })
            .then(data => {
              setMessage('Aluno cadastrado no Curso com sucesso');
              setSuccessMessage(true);
              setOpenMessage(true);
              setTimeout(() => {
                setOpenMessage(false);
              }, 2000);
              getAlunos();
              setLoadingNewUser(false);
            })
            .catch((error: any) => {
              setMessage('Erro ao cadastrar aluno no Curso');
              setSuccessMessage(false);
              setOpenMessage(true);
              setTimeout(() => {
                setOpenMessage(false);
              }, 2000);
              setLoadingNewUser(false);
            });
        })
        .catch(() => {
          setMessage('Erro ao cadastrar aluno no Curso');
          setSuccessMessage(false);
          setOpenMessage(true);
          setTimeout(() => {
            setOpenMessage(false);
          }, 2000);
          setLoadingNewUser(false);
        });
    } else {
      setMessage('Erro ao cadastrar aluno no Curso');
      setSuccessMessage(false);
      setOpenMessage(true);
      setTimeout(() => {
        setOpenMessage(false);
      }, 2000);
    }
  };

  const getComboBoxData = () => {
    const aux: any = [];
    usuarios.forEach(usuario => {
      const temp = alunos.filter((aluno: any) => usuario.key === aluno.key);
      if (temp.length === 0) aux.push(usuario);
    });
    return aux;
  };

  return (
    <>
      <Container>
        <Path>{`Dashboard / Cursos / ${ID ? 'Curso' : 'Novo Curso'}`}</Path>
        <HeaderPages
          title={`${ID ? 'Curso' : 'Novo Curso'}`}
          urlBack="/curso"
        />

        <Loading loading={loading}>
          <FormContainer>
            <h3>Dados do Curso</h3>
            <div className="emailFone">
              <FormGroup>
                <h2>NOME*</h2>
                <Input
                  id="nameCurso"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormGroup>

              <FormGroup inline>
                <h2>REPROVÁVEL</h2>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </FormGroup>
            </div>

            <FormGroup>
              <h2>MATÉRIAS*</h2>
              <Table
                data={getDataTable(materias)}
                columns={['Nome', 'Horas']}
                withAction
              />
            </FormGroup>

            <div className="wrappperButton">
              <Button onClick={handleSubmit}>
                {`${ID ? 'Atualizar Curso' : 'Criar Curso'}`}
              </Button>
            </div>
          </FormContainer>
        </Loading>

        {ID && (
          <Loading loading={loadingNewUser}>
            <FormContainer>
              <h3>Alunos</h3>
              <div className="wrappperHeader">
                <div className="combobox">
                  <Combobox
                    value={newUser}
                    options={getComboBoxData()}
                    arrayKey="name"
                    onChange={newValue => {
                      if (!newValue) setNewUser([]);
                      setNewUser(newValue);
                    }}
                  />
                </div>
                <Button onClick={setUser}>Adicionar Aluno</Button>
              </div>
              <Table data={getDataAlunosTable(alunos)} columns={['Nome']} />
            </FormContainer>
          </Loading>
        )}
      </Container>

      <Message open={openMessage} message={message} success={successMessage} />
    </>
  );
};

export default Form;
