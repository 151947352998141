/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '@hooks/toast';

import Input from '@components/common/input';
import Button from '@components/common/button';
import Loading from '@components/common/loading';
import Combobox from '@components/common/combobox';
import HeaderPages from '@components/common/headerPages';
import { Message } from '@components/common/modals';

import { firestore, storage } from '@services/firebaseConfig';
import { CONTEUDOS_STORAGE } from '@utils/firebaseCollections';

import { Container, Path, WrapperDatas } from './styles';

const TYPES = [
  { type: 'CURSO_BATISMO', name: 'Batismo' },
  { type: 'PALAVRA_CELULA', name: 'Palavra de célula' },
  { type: 'CELEBRACAO_DOMINGO', name: 'Palavra de celebrações' },
];

const PalavraCelula: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ID = query.get('id');

  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState('');

  const [name, setName] = useState('');
  const [tipoConteudo, setTipoConteudo] = useState<Array<any> | []>([]);

  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('Usuário cadastrado com sucesso');
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (ID) {
      setLoading(true);

      firestore
        .collection(CONTEUDOS_STORAGE)
        .doc(ID)
        .get()
        .then((data: any) => {
          const temp = { ...data.data(), key: data.id };

          const auxType = [];
          switch (temp.type) {
            case 'CURSO_BATISMO':
              auxType.push(TYPES[0]);
              break;
            case 'PALAVRA_CELULA':
              auxType.push(TYPES[1]);
              break;
            case 'CELEBRACAO_DOMINGO':
              auxType.push(TYPES[2]);
              break;

            default:
              break;
          }

          setName(temp.name);
          setFile(temp.fileUrl);
          setFileName(temp.fileName);
          setTipoConteudo(auxType);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [ID]);

  const messageSuccess = (text: string) => {
    setMessage(text);
    setSuccessMessage(true);
    setOpenMessage(true);
    setTimeout(() => {
      setOpenMessage(false);
      history.push('/conteudos');
    }, 2000);
    setLoading(false);
  };

  const messageErro = (text: string) => {
    setMessage(text);
    setSuccessMessage(false);
    setOpenMessage(true);
    setTimeout(() => {
      setOpenMessage(false);
    }, 2000);
    setLoading(false);
  };

  const handlePdf = (e: any) => {
    const auxFile = e.target.files[0];
    setFile(auxFile);
    setName(auxFile.name);
  };

  const handleSubmit = () => {
    if (name === '' || tipoConteudo.length === 0 || !file) {
      addToast({
        type: 'error',
        title: 'Notificação!',
        description: 'Preencha todos os campos!',
      });
      return;
    }

    setLoading(true);

    const auxFileName = `${tipoConteudo[0].type}_${name}_${file.name}`;
    const uploadTask = storage
      .ref(`/${CONTEUDOS_STORAGE}/${auxFileName}`)
      .put(file);

    uploadTask.on(
      'state_changed',
      snapShot => {
        console.log('snapShot');
        console.log(snapShot);
      },
      err => {
        setMessage('Erro ao cadastrar conteúdo');
        setSuccessMessage(false);
        setOpenMessage(true);
        setTimeout(() => {
          setOpenMessage(false);
        }, 2000);
      },
      () => {
        storage
          .ref(CONTEUDOS_STORAGE)
          .child(auxFileName)
          .getDownloadURL()
          .then(fireBaseUrl => {
            firestore
              .collection(CONTEUDOS_STORAGE)
              .add({
                name,
                fileName: auxFileName,
                fileUrl: fireBaseUrl,
                type: tipoConteudo[0].type,
              })
              .then(() => messageSuccess('Conteúdo cadastrado com sucesso'))
              .catch(() => messageErro('Erro ao cadastrar conteúdo'));
          });
      },
    );
  };

  const handleDelete = () => {
    if (ID) {
      setLoading(true);
      firestore
        .collection(CONTEUDOS_STORAGE)
        .doc(ID)
        .delete()
        .then(() => {
          storage
            .ref(CONTEUDOS_STORAGE)
            .child(fileName)
            .delete()
            .then(() => messageSuccess('Palavra excluida com sucesso'))
            .catch(() => messageErro('Erro ao excluir palavra'));
        })
        .catch(() => messageErro('Erro ao excluir palavra'));
    }
  };

  return (
    <>
      <Container>
        <Path>Dashboard / Conteúdos / Form</Path>
        <HeaderPages title="Conteúdos" urlBack="/conteudos" />

        <h3>
          Selecione o arquivo em PDF, insira o título que irá aparecer no site e
          escolha qual o tipo desse conteúdo
        </h3>
        <Loading loading={loading}>
          <p style={{ marginTop: '20px' }}>Selecione o arquivo em PDF</p>
          <input type="file" onChange={e => handlePdf(e)} />

          <WrapperDatas>
            <div>
              <p>Título do arquivo</p>
              <Input
                id="nameCurso"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                disabled={!file}
              />
            </div>
          </WrapperDatas>

          <WrapperDatas>
            <div>
              <p>Tipo de conteúdo</p>
              <Combobox
                value={tipoConteudo}
                arrayKey="name"
                options={TYPES}
                onChange={newValue => setTipoConteudo(newValue)}
              />
            </div>
          </WrapperDatas>

          <div className="wrappperButton">
            {ID && <Button onClick={handleDelete}>Excluir Arquivo</Button>}
            {!ID && (
              <Button onClick={handleSubmit} disabled={!!ID}>
                {ID ? 'Atualizar Arquivo' : 'Salvar Arquivo'}
              </Button>
            )}
          </div>
        </Loading>

        <Message
          open={openMessage}
          message={message}
          success={successMessage}
        />
      </Container>
    </>
  );
};

export default PalavraCelula;
