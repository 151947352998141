export const ROLES = {
  ADMIN: { KEY: 'admin', VALUE: 'Administrador' },
  STUDENT: { KEY: 'student', VALUE: 'Aluno' },
  TEACHER: { KEY: 'teacher', VALUE: 'Professor' },
};

export const ARRAY_ROLES = [
  { KEY: 'admin', VALUE: 'Administrador' },
  { KEY: 'student', VALUE: 'Aluno' },
  { KEY: 'teacher', VALUE: 'Professor' },
];
