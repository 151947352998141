import { createGlobalStyle } from 'styled-components';
import { bahnschrift } from '@assets/fonts/bahnschrift-cufonfonts-webfont';
import { segoeUi } from '@assets/fonts/segoe-ui-4-cufonfonts-webfont';

export default createGlobalStyle`
  ${bahnschrift}
  ${segoeUi}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.colors.background};
    font: 400 16px "Roboto", sans-serif;
  }

  button{
    cursor: pointer;
  }
`;
