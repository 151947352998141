import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '@components/common/input';
import Button from '@components/common/button';
import Loading from '@components/common/loading';
import HeaderPages from '@components/common/headerPages';
import Combobox from '@components/common/combobox';
import { Message } from '@components/common/modals';

import { ARRAY_ROLES } from '@utils/roles';
import { USERS_COLLECTION } from '@utils/firebaseCollections';

import { createUser } from '@services/authentication';
import { setNewItem } from '@services/commons';

import { Container, Path, FormContainer, FormGroup } from './styles';

const Form: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('Usuário cadastrado com sucesso');
  const [successMessage, setSuccessMessage] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [fone, setFone] = useState('');
  const [atribuicoes, setAtribuicoes] = useState([] as any);

  const handleSubmit = () => {
    setLoading(true);
    const role: string[] = [];
    atribuicoes.forEach((element: { KEY: string }) => {
      role.push(element.KEY);
    });
    const params = {
      name,
      role,
      email,
      tel: fone || null,
      senhaPadrao: true,
    };
    createUser(email)
      .then(uid => {
        setNewItem(USERS_COLLECTION, uid, params).then((response: any) => {
          setMessage('Usuário cadastrado com sucesso');
          setSuccessMessage(true);
          setOpenMessage(true);
          setTimeout(() => {
            setOpenMessage(false);
            history.push('/usuario');
          }, 2000);
          setLoading(false);
        });
      })
      .catch(error => {
        setMessage('Erro ao cadastrar o usuário');
        setSuccessMessage(false);
        setOpenMessage(true);
        setTimeout(() => {
          setOpenMessage(false);
        }, 2000);
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        <Path>Dashboard / Usuários / Novo Usuário</Path>
        <HeaderPages title="Novo Usuário" urlBack="/usuario" />

        <Loading loading={loading}>
          <FormContainer>
            <FormGroup>
              <h2>NOME*</h2>
              <Input
                id="nameUsuario"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormGroup>

            <div className="emailFone">
              <FormGroup>
                <h2>E-MAIL*</h2>
                <Input
                  id="emailUsuario"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <h2>TELEFONE</h2>
                <Input
                  id="foneUsuario"
                  type="tel"
                  value={fone}
                  maxLength={11}
                  onChange={e => setFone(e.target.value)}
                />
              </FormGroup>
            </div>

            <FormGroup>
              <h2>ATRIBUIÇÕES* (selecione pelo menos uma atribuição)</h2>
              <Combobox
                value={atribuicoes}
                options={ARRAY_ROLES}
                arrayKey="VALUE"
                onChange={newValue => setAtribuicoes(newValue)}
                multiple
              />
            </FormGroup>

            <div className="wrappperButton">
              <Button onClick={handleSubmit}>Criar Usuário</Button>
            </div>
          </FormContainer>
        </Loading>
      </Container>

      <Message open={openMessage} message={message} success={successMessage} />
    </>
  );
};

export default Form;
