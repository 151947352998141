import styled from 'styled-components';

interface IItemProps {
  active?: boolean;
  whiteBackground: boolean;
}

export const Container = styled('div')<IItemProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: ${({ whiteBackground }) => (whiteBackground ? `black` : `white`)};
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    display: flex;
  }
`;

export const Item = styled('div')<IItemProps>`
  padding: 0px 10px;
  margin: 0px 10px;
  transition: 0.2s;

  border-bottom: ${({ active, whiteBackground }) =>
    active && (whiteBackground ? `2px solid black` : `2px solid white`)};

  font-weight: ${({ active }) => active && 'bold'};

  :hover {
    cursor: pointer;
    opacity: 0.9;
    ${({ active, whiteBackground }) =>
      !active &&
      ` border-bottom: ${
        whiteBackground ? `1px solid black` : `1px solid white`
      }; `}
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;

  margin: 20px 0px;

  a {
    color: black;
    text-decoration: none;
  }
`;

export const MobileItem = styled.div`
  padding: 10px 5px;
  transition: 0.2s;

  font-weight: bold;
  font-size: 25px;

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
