/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import { Container } from './styles';

import pix from '../../../assets/images/pix.jpeg';
import logoPix from '../../../assets/images/logoPix.png';

const Donate: React.FC = () => {
  const onClickDoacoes = () => {
    window.open(
      'https://atos6.com/organizations/6379/financial/online_contributions/new',
    );
  };

  return (
    <Container>
      <h1>Doação e Generosidade</h1>
      <div>
        <h2>Contas Bancárias:</h2>
        <div>
          <div className="contas">
            <h3>
              Banco do Brasil: <br />
              AG: 804-4 <br />
              C/C: 23075-8 <br />
            </h3>
            <h3>
              Banco do Bradesco: <br />
              AG: 1554-7 <br />
              C/C: 018351-2 <br />
            </h3>
          </div>

          <div className="logoPix">
            <img src={logoPix} alt="Logo Pix" />
            <h3>CNPJ:</h3>
            <h3>05581030000152</h3>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Donate;
