import React, { createContext, useCallback, useState, useContext } from 'react';
import { useToast } from '@hooks/toast';

import { auth, firestore } from '@services/firebaseConfig';
import { USERS_COLLECTION } from '@utils/firebaseCollections';

interface IUser {
  uid: string;
  email: string;
  role: string;
  turmaId: string;
  senhaPadrao: boolean;
  photo?: string;
  name?: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface ISignInCredentials {
  login: string;
  password: string;
}

interface IUpdateCredentials {
  password: string;
}

interface IResetPassword {
  email: string;
}

interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<any>;
  signOut(): void;
  updatePassword(credentials: IUpdateCredentials): Promise<void>;
  resetPassword(credentials: IResetPassword): Promise<void>;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const [data, setData] = useState<IAuthState>(() => {
    // const section = localStorage.getItem('@SiteAgape:section');

    const token = localStorage.getItem('@SiteAgape:token');
    const user = localStorage.getItem('@SiteAgape:user');

    if (token && user) {
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
      // if (isToday(new Date(section))) return { token, user: JSON.parse(user) };
      // addToast({
      //   type: 'info',
      //   title: 'Notificação!',
      //   description: 'Sessão expirada, conecte novamente!',
      // });
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ login, password }) => {
    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(login, password)
        .then(responseUser => {
          if (responseUser.user) {
            const { refreshToken, uid } = responseUser.user;
            firestore
              .collection(USERS_COLLECTION)
              .doc(uid)
              .get()
              .then(userDoc => {
                const { ...rest } = userDoc.data();
                const {
                  displayName,
                  email,
                  photoURL,
                  role,
                  turmaId,
                  senhaPadrao,
                } = rest;

                const userData: IUser = {
                  email,
                  uid,
                  role,
                  turmaId,
                  senhaPadrao,
                };
                if (displayName) userData.name = displayName;
                if (photoURL) userData.photo = photoURL;

                const date = new Date();
                localStorage.setItem('@SiteAgape:section', date.toString());
                localStorage.setItem('@SiteAgape:token', refreshToken);
                localStorage.setItem(
                  '@SiteAgape:user',
                  JSON.stringify(userData),
                );

                setData({ token: refreshToken, user: userData });
                resolve(userData);
              });
          }
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          reject(error);
        });
    });
  }, []);

  const signOut = useCallback(() => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem('@SiteAgape:token');
        localStorage.removeItem('@SiteAgape:user');
        localStorage.removeItem('@SiteAgape:section');

        setData({} as IAuthState);
      })
      .catch(error => {
        // An error happened.
      });
  }, []);

  const updatePassword = useCallback(
    async ({ password }) => {
      const { currentUser } = auth;

      if (currentUser) {
        currentUser
          .updatePassword(password)
          .then(response => {
            const auxUser = localStorage.getItem('@SiteAgape:user');
            const auxToken = localStorage.getItem('@SiteAgape:token');
            let aux: IAuthState;
            if (auxUser && auxToken) {
              aux = { token: auxToken, user: JSON.parse(auxUser) };
            } else {
              aux = { ...data };
            }
            aux.user.senhaPadrao = false;

            const fb = firestore
              .collection(USERS_COLLECTION)
              .doc(currentUser.uid);

            fb.get().then(user => {
              const temp = user.data();
              if (temp) {
                temp.senhaPadrao = false;
                fb.set(temp)
                  .then(() => {
                    localStorage.setItem(
                      '@SiteAgape:user',
                      JSON.stringify(aux),
                    );
                    setData(aux);
                  })
                  .catch(() => {
                    addToast({
                      type: 'error',
                      title: 'Erro no cadastro!',
                      description: 'Erro ao cadastar nova senha!',
                    });
                    signOut();
                  });
              }
            });
          })
          .catch(error => {
            const errorCode = error.code;
            const errorMessage = error.message;
          });
      }
    },
    [addToast, data, signOut],
  );

  const resetPassword = useCallback(async ({ email }) => {
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        console.log('email enviado');
      })
      .catch(function (error) {
        // An error happened.
      });
  }, []);

  auth.onAuthStateChanged(user => {
    const today = new Date();
    const dateTeste = localStorage.getItem('@SiteAgape:section');
    if (dateTeste) {
      const sectionDate = new Date(dateTeste);
      if (
        today.getDate() > sectionDate.getDate() ||
        today.getMonth() > sectionDate.getMonth() ||
        today.getFullYear() > sectionDate.getFullYear()
      ) {
        signOut();
      }
    }
  });

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updatePassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
