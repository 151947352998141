import React from 'react';

import Pastors from '@components/site/pastors';

import Header from '@components/site/header';
import AboutUs from '@components/site/about-us';
import Wellcome from '@components/site/welcome';

// Images
import mission from '@assets/images/icons/mission.png';
import vision from '@assets/images/icons/vision.png';
import values from '@assets/images/icons/values.png';
import propose from '@assets/images/icons/propose.png';
import culture from '@assets/images/icons/culture.png';

import { Container, Wrapper, Item } from './styles';

const Sobre: React.FC = () => {
  return (
    <>
      <Header menuType="SOBRE" isBlack />
      <Container>
        <Wellcome />

        <Wrapper>
          <Item>
            <div>
              <img src={vision} alt="Icone Visão" />
            </div>
            <h1>Visão</h1>
            <p>Ganhar todas as pessoas para JESUS!</p>
          </Item>

          <Item>
            <div>
              <img src={mission} alt="Icone Missão" />
            </div>
            <h1>Missão</h1>
            <p>
              Transformar pessoas comuns em extraordinários discípulos de Jesus!
            </p>
          </Item>

          <Item>
            <div>
              <img src={values} alt="Icone Valores" />
            </div>
            <h1>Valores</h1>
            <p>
              Somos uma igreja que crê e vive a Palavra de Deus, em amor,
              incluindo pessoas na família do Céu e trazendo o sobrenatural à
              Terra em todas as áreas do viver, de modo contextualizado,
              excelente e transparente.
            </p>
          </Item>

          <Item>
            <div>
              <img src={propose} alt="Icone Proposito" />
            </div>
            <h1>Propósitos</h1>
            <p>
              Ser uma igreja bíblica, dirigida pelos propósitos de Jesus:
              Missões, Adoração, Comunhão, Serviço e Discipulado.
            </p>
          </Item>

          <Item>
            <div>
              <img src={culture} alt="Icone cultura" />
            </div>
            <h1>Cultura</h1>
            <p>
              Ser uma igreja família, pastoreada por uma rede de pequenos grupos
              sobre os princípios dos cinco dons de governo!
            </p>
          </Item>
        </Wrapper>

        <Pastors />
        <div className="footer">
          <AboutUs />
        </div>
      </Container>
    </>
  );
};

export default Sobre;
