import styled, { keyframes } from 'styled-components';

interface IBlockProps {
  loading: boolean;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Block = styled('div')<IBlockProps>`
  position: absolute;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  background-color: black;
  border-radius: 10px;

  opacity: 0.5;

  z-index: 1000;
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  width: 45px;
  height: 45px;

  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
  border-right: 2px solid ${({ theme }) => theme.colors.secondary};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  border-left: 4px solid black;
  background: transparent;
  border-radius: 50%;
`;
