const Theme = {
  colors: {
    background: 'white',
    backgroundHeader: '#F2F2F2',
    blueBackground: '#1E3A4D',

    primary: '#173F5F',
    secondary: '#575757',
    tertiary: '#3CAEA3',

    warning: '#F6D55C',
    danger: '#ED553B',

    white: 'white',
    black: 'black',
    dimGray: '#696969',
    gray: '#808080',
    darkGray: '#A9A9A9',
    silver: '#C0C0C0',
    lightGrey: '#D3D3D3',
  },
  sizes: {
    mobile: '576px',
    tablet: '992px',
    desktop: '1200px',
    headerHeight: '60px',
  },
};

export default Theme;
