import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .footer {
    margin-top: 70px;
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 180px;
  padding-top: 100px;

  h1 {
    padding: 5px 15px;
    margin-bottom: 10px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .slick-prev {
    ::before {
      color: black;
    }
  }

  .slick-next {
    ::before {
      color: black;
    }
  }

  > div {
    margin-top: 30px;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 10px;
  padding: 0px 15px;
`;

export const CardTest = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 3px 15px;

  .iconAndLabel {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 169px;

    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.backgroundHeader};

    border: none;
    box-shadow: none;
    border-radius: 20px;

    :hover {
      background-color: ${({ theme }) => theme.colors.silver};
    }

    h1 {
      text-align: center;
      font-size: 20px;
      font-weight: 100;
    }
  }

  transition: 0.5s;

  cursor: pointer;
`;
