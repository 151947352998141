import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Input from '@components/common/input';
import Button from '@components/common/button';
import Loading from '@components/common/loading';
import Combobox from '@components/common/combobox';
import HeaderPages from '@components/common/headerPages';
import { Message } from '@components/common/modals';

import {
  AULAS_COLLECTION,
  CURSO_COLLECTION,
  CURSO_MATERIAS_COLLECTION,
} from '@utils/firebaseCollections';
import { firestore } from '@services/firebaseConfig';
import { createItem } from '@services/commons';

import {
  Container,
  Path,
  FormContainer,
  FormGroup,
  WrapperInput,
} from './styles';

const Form: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ID = query.get('id');

  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('Usuário cadastrado com sucesso');
  const [successMessage, setSuccessMessage] = useState(false);

  const [link, setLink] = useState('');
  const [ordem, setOrdem] = useState(0);
  const [materia, setMateria] = useState([] as any);
  const [curso, setCurso] = useState([] as any);
  const [materiaID, setMateriaID] = useState('');

  const [materiasData, setMateriasData] = useState<Array<any> | []>([]);
  const [cursosData, setCursosData] = useState<Array<any> | []>([]);

  useEffect(() => {
    firestore
      .collection(CURSO_COLLECTION)
      .get()
      .then(data => {
        if (data.docs.length) {
          const aux: Array<any> = [];
          data.forEach((doc: any) => {
            const temp = { ...doc.data(), key: doc.id };
            aux.push(temp);
          });
          setCursosData(aux);
        }
      });
  }, []);

  useEffect(() => {
    if (ID && cursosData.length > 0) {
      setLoading(true);

      firestore
        .collection(AULAS_COLLECTION)
        .doc(ID)
        .get()
        .then((data: any) => {
          const temp = { ...data.data(), key: data.id };
          const filter = cursosData.filter(item => item.key === temp.curso_id);
          if (filter.length > 0) setCurso(filter);
          setLink(temp.link);
          setOrdem(temp.ordem);
          setMateriaID(temp.materia_id);
        });
    }
  }, [cursosData]);

  useEffect(() => {
    if (curso.length > 0) {
      const idCurso = curso[0].key;
      firestore
        .collection(CURSO_COLLECTION)
        .doc(idCurso)
        .collection(CURSO_MATERIAS_COLLECTION)
        .get()
        .then(data => {
          if (data.docs.length) {
            const aux: Array<any> = [];
            data.forEach((doc: any) => {
              const temp = { ...doc.data(), key: doc.id };
              aux.push(temp);
            });
            if (materiaID !== '') {
              const filter = aux.filter(item => item.key === materiaID);
              if (filter.length > 0) setMateria(filter);
            }
            setMateriasData(aux);
            setLoading(false);
          }
        });
    }
  }, [curso, materiaID]);

  const getLinkEmbed = () => {
    if (link !== '') {
      if (link.includes('?')) {
        const split = link.split('?');
        const querySplit = new URLSearchParams(split[1]);
        const V = querySplit.get('v');
        const url = `https://youtube.com/embed/${V}`;
        return url;
      }

      if (link.includes('.be/')) {
        const split = link.split('.be/');
        const url = `https://youtube.com/embed/${split[1]}`;
        return url;
      }
    }

    return link;
  };

  const handleSubmit = () => {
    setLoading(true);

    const referenceCurso = `/cursos/${curso[0].key}`;

    const params = {
      link: getLinkEmbed(),
      ordem,
      curso: firestore.collection(CURSO_COLLECTION).doc(curso[0].key),
      curso_id: curso[0].key,
      curso_nome: curso[0].nome,
      materia: firestore
        .collection(CURSO_COLLECTION)
        .doc(curso[0].key)
        .collection(CURSO_MATERIAS_COLLECTION)
        .doc(materia[0].key),
      materia_id: materia[0].key,
      materia_nome: materia[0].nome,
    };

    createItem(AULAS_COLLECTION, params)
      .then((response: any) => {
        setMessage('Aula cadastrada com sucesso');
        setSuccessMessage(true);
        setOpenMessage(true);
        setTimeout(() => {
          setOpenMessage(false);
          history.push('/aulas');
        }, 2000);
        setLoading(false);
      })
      .catch((error: any) => {
        setMessage('Erro ao cadastrar aula');
        setSuccessMessage(false);
        setOpenMessage(true);
        setTimeout(() => {
          setOpenMessage(false);
        }, 2000);
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        <Path>Dashboard / Aulas / Nova Aulas</Path>
        <HeaderPages title="Aulas" urlBack="/aulas" />

        <Loading loading={loading}>
          <FormContainer>
            <FormGroup>
              <h2>CURSO*</h2>
              <Combobox
                value={curso}
                options={cursosData}
                arrayKey="nome"
                onChange={newValue => {
                  if (!newValue) setMateria([]);
                  setCurso(newValue);
                }}
              />
            </FormGroup>

            {curso.length > 0 && (
              <FormGroup>
                <h2>MATÉRIA*</h2>
                <Combobox
                  value={materia}
                  options={materiasData}
                  arrayKey="nome"
                  onChange={newValue => setMateria(newValue)}
                />
              </FormGroup>
            )}

            {materia.length > 0 && (
              <>
                <FormGroup inline>
                  <WrapperInput>
                    <FormGroup>
                      <h2>LINK*</h2>
                      <Input
                        id="linkAula"
                        type="text"
                        value={link}
                        onChange={e => setLink(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup style={{ margin: '15px 0px' }}>
                      <h2>ORDEM DA AULA*</h2>
                      <Input
                        id="ordemAula"
                        type="number"
                        value={ordem}
                        min={0}
                        style={{ width: '100px' }}
                        onChange={e => setOrdem(parseInt(e.target.value, 10))}
                      />
                    </FormGroup>
                  </WrapperInput>

                  <iframe
                    width="30%"
                    height="200"
                    frameBorder="0"
                    src={getLinkEmbed()}
                    title="preview"
                  />
                </FormGroup>

                <div className="wrappperButton">
                  <Button onClick={handleSubmit}>
                    {`${ID ? 'Atualizar Aula' : 'Criar Aula'}`}
                  </Button>
                </div>
              </>
            )}
          </FormContainer>
        </Loading>

        <Message
          open={openMessage}
          message={message}
          success={successMessage}
        />
      </Container>
    </>
  );
};

export default Form;
