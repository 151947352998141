import React from 'react';

import arrowBack from '@assets/images/icons/arrowBack.svg';

import Button from '@components/common/button';
import { Container } from './styles';

type HeaderPagesProps = {
  title: string;
  urlBack?: string;
  buttonName?: string;
  buttonClick?: () => void;
};

const HeaderPages: React.FC<HeaderPagesProps> = ({
  title,
  urlBack,
  buttonName,
  buttonClick,
}: HeaderPagesProps) => {
  return (
    <Container>
      <div>
        {urlBack !== '' && (
          <a href={urlBack}>
            <img src={arrowBack} alt="icone de voltar" />
          </a>
        )}
        <h1>{title}</h1>
      </div>
      <div>
        {buttonName !== '' && (
          <Button onClick={buttonClick}>{buttonName}</Button>
        )}
      </div>
    </Container>
  );
};

HeaderPages.defaultProps = {
  urlBack: '',
  buttonName: '',
  buttonClick: undefined,
};

export default HeaderPages;
