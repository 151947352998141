import React from 'react';
import { Switch } from 'react-router-dom';

// Publics Routes
import Home from '@pages/Home';
import PalavraCelula from '@pages/PalavrasCelula';
import Sobre from '@pages/Sobre';
import SignIn from '@pages/Auth/SignIn';
import CelulasPage from '@pages/Celulas/Page';

// Private Routes
import Class from '@pages/Class';

import Library from '@pages/Library/Dash';
import LibraryList from '@pages/Library/List';
import LibraryForm from '@pages/Library/Form';

import AulasList from '@pages/Aulas/List';
import AulasForm from '@pages/Aulas/Form';
import AulasVisualizacao from '@pages/Aulas/Visualizacao';

// Admin Routes
import Dashboard from '@pages/Dashboard';
import UsuarioList from '@pages/Usuario/List';
import UsuarioForm from '@pages/Usuario/Form';
import CursoList from '@pages/Curso/List';
import CursoForm from '@pages/Curso/Form';
import Curso from '@pages/Curso/Dash';

import ConteudosList from '@pages/Conteudos/List';
import ConteudosForm from '@pages/Conteudos/Form';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/sign-in" exact component={SignIn} isAuthentication />
    <Route path="/conteudos-page" exact component={PalavraCelula} />
    <Route path="/" exact component={Home} />
    <Route path="/sobre" exact component={Sobre} />
    <Route path="/ministerios/celulas" exact component={CelulasPage} />

    <Route path="/class" exact component={Class} isPrivate />

    <Route path="/library" exact component={Library} isPrivate />
    <Route path="/library/list" exact component={LibraryList} isPrivate />
    <Route path="/library/form" exact component={LibraryForm} isPrivate />

    <Route path="/aulas" exact component={AulasList} isPrivate />
    <Route path="/aulas/form" exact component={AulasForm} isPrivate />
    <Route
      path="/aulas/visualizacao"
      exact
      component={AulasVisualizacao}
      isPrivate
    />

    <Route path="/dashboard" exact component={Dashboard} isPrivate isAdmin />
    <Route path="/usuario" exact component={UsuarioList} isPrivate isAdmin />
    <Route
      path="/usuario/form"
      exact
      component={UsuarioForm}
      isPrivate
      isAdmin
    />

    <Route path="/curso" exact component={CursoList} isPrivate isAdmin />
    <Route path="/curso/form" exact component={CursoForm} isPrivate isAdmin />
    <Route path="/curso/dash" exact component={Curso} isPrivate />

    <Route
      path="/conteudos"
      exact
      component={ConteudosList}
      isPrivate
      isAdmin
    />
    <Route
      path="/conteudos/form"
      exact
      component={ConteudosForm}
      isPrivate
      isAdmin
    />
  </Switch>
);

export default Routes;
