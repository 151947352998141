import React from 'react';

import { Container } from './styles';

type TableProps = {
  columns: Array<string>;
  data: Array<string>;
  withAction?: boolean;
};

const Table: React.FC<TableProps> = ({ data, columns, withAction }) => {
  return (
    <Container>
      <table>
        <tr>
          {columns.map(item => (
            <th>{item}</th>
          ))}

          {withAction && <th />}
        </tr>
        {data.map((row: any) => (
          <tr>
            {row.map((item: any) => (
              <td>{item}</td>
            ))}
          </tr>
        ))}
      </table>
    </Container>
  );
};

Table.defaultProps = {
  withAction: false,
};

export default Table;
