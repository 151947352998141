import React from 'react';

import { Container } from './styles';

const Wellcome: React.FC = () => {
  return (
    <>
      <Container>
        <h1>Seja bem-vindo a Igreja Batista Ágape</h1>

        <h5>Desde 2003</h5>
        <h3>
          Participar de uma comunidade é um dos melhores caminhos para encorajar
          o crescimento espiritual. Através dos pequenos grupos, faixas etárias,
          discipulado e ministérios, você terá a oportunidade de se conectar e
          crescer em seu relacionamento com Cristo.
          <br />
          Igreja Batista Ágape,
          <br />
          Uma família para pertencer!
        </h3>
      </Container>
    </>
  );
};

export default Wellcome;
