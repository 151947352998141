import React from 'react';

import HeaderComponent from '@components/site/header';
import { Container } from './styles';

type HeroProps = {
  type: string;
};

const Hero: React.FC<HeroProps> = ({ type }: HeroProps) => {
  return (
    <>
      <HeaderComponent menuType={type} />
      <Container>
        <div className="box">
          <h1>
            Uma família
            <br />
            para pertencer
          </h1>
          <h1 className="box-primary">
            Uma família
            <br />
            para pertencer
          </h1>
        </div>
      </Container>
    </>
  );
};

export default Hero;
