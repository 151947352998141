import React from 'react';
import ReactDOM from 'react-dom';

import Bootstrap from './Bootstrap';

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById('root'),
);
