/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDmytgqLoeowfClDfvF-8azINiJaqUDkrE',
  authDomain: 'site-ministerio-agape.firebaseapp.com',
  databaseURL: 'https://site-ministerio-agape.firebaseio.com',
  projectId: 'site-ministerio-agape',
  storageBucket: 'site-ministerio-agape.appspot.com',
  messagingSenderId: '29906388812',
  appId: '1:29906388812:web:a9aa03a6c50a397f1271e9',
  measurementId: 'G-90PMEP8FVR',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
