import styled from 'styled-components';

interface IBlockProps {
  open: boolean;
}

export const Block = styled('div')<IBlockProps>`
  position: absolute;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1001;
`;
