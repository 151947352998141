import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getItems } from '@services/commons';
import { USERS_COLLECTION } from '@utils/firebaseCollections';

import HeaderPages from '@components/common/headerPages';
import Loading from '@components/common/loading';
import Table from '@components/common/table';

import { Container, Path } from './styles';

const List: React.FC = () => {
  const history = useHistory();
  const LIMIT_ITEMS = 15;

  const [loading, setLoading] = useState(false);
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [users, setUsers] = useState<Array<any> | []>([]);

  useEffect(() => {
    setLoading(true);
    getItems(USERS_COLLECTION, 'name').then((data: any) => {
      const map: Array<any> = [];
      if (data.docs.length) {
        data.forEach((doc: any) => {
          const aux = { ...doc.data(), key: doc.id };
          map.push(aux);
        });
        setStartAt(data.docs[0]);
        setEndAt(data.docs[data.docs.length - 1]);
      }
      setUsers(map);
      setLoading(false);
    });
  }, []);

  const getDataTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      aux.push([item.name, item.email, item.role.join(', ')]);
    });
    return aux;
  };

  return (
    <Container>
      <Path>Dashboard / Usuários</Path>
      <HeaderPages
        title="Usuários"
        urlBack="/dashboard"
        buttonName="Novo Usuário"
        buttonClick={() => history.push('/usuario/form')}
      />

      <Loading loading={loading}>
        <Table
          data={getDataTable(users)}
          columns={['Nome', 'E-mail', 'Atribuições']}
          withAction
        />
      </Loading>
    </Container>
  );
};

export default List;
