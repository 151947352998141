import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  padding: 10px;

  width: 48%;

  border: 1px solid black;
  border-radius: 15px;

  overflow: hidden;

  div {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  min-width: 150px;
  height: 200px;
  overflow: hidden;
  margin: 0px;
  margin-right: 20px;

  border-radius: 10px;

  img {
    height: 200px;
    width: 150px;
    transition: 0.5s all ease-in-out;

    :hover {
      transform: scale(1.1);
    }
  }
`;
export const LivrosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 3%;
  row-gap: 10px;

  margin-top: 25px;

  .wrapperButton {
    align-items: flex-end;

    margin-top: auto;
  }
`;
