import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0px;

  h1 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 30px;
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      height: 60px;
      width: auto;

      margin: 30px;
    }

    @media only screen and (max-width: ${({ theme }) => theme.sizes.tablet}) {
      flex-direction: column;
    }
  }
`;
