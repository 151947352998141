import styled from 'styled-components';

interface IOptionsProps {
  open: boolean;
}
interface IOptionProps {
  include: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 5px 0px;
  padding: 0px 10px;

  width: 100%;
  height: 35px;

  font-size: 17px;

  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 2px;

  button {
    background-color: white;
    border: none;

    img {
      height: 20px;
      width: auto;
      cursor: pointer;
    }
  }
`;

export const Selected = styled.div`
  display: flex;

  div {
    margin-left: 5px;
  }
`;

export const Option = styled('div')<IOptionProps>`
  padding: 5px 10px;
  background-color: ${({ include }) => (include ? 'lightgray' : 'white')};

  :hover {
    background-color: lightgray;
  }

  cursor: pointer;
`;

export const Options = styled('div')<IOptionsProps>`
  position: absolute;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: 0px;
  top: 39px;

  width: 100%;
  background-color: white;
  border: 1px solid lightgray;

  z-index: 100;
`;
