import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 200px;

  color: ${({ theme }) => theme.colors.black};

  h1 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.1rem;
  }
  h5 {
    font-size: 15px;
    font-family: 'Segoe UI';
    font-weight: normal;
    padding: 20px 0px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 10px 100px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    padding: 10px 30px;
  }
`;
