import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '@hooks/toast';

import Input from '@components/common/input';
import Button from '@components/common/button';
import Loading from '@components/common/loading';
import HeaderPages from '@components/common/headerPages';
import { Message } from '@components/common/modals';

import { LIVROS_COLLECTION } from '@utils/firebaseCollections';

import { firestore, storage } from '@services/firebaseConfig';
import {
  Container,
  Path,
  FormContainer,
  FormGroup,
  ImageWrapper,
} from './styles';

const Form: React.FC = () => {
  const { addToast } = useToast();
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const ID = query.get('id');

  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState(
    'Material didático cadastrado com sucesso',
  );
  const [successMessage, setSuccessMessage] = useState(false);

  const [nome, setNome] = useState('');
  const [description, setDescription] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<any>();

  useEffect(() => {
    if (ID) {
      setLoading(true);
      firestore
        .collection(LIVROS_COLLECTION)
        .doc(ID)
        .get()
        .then((data: any) => {
          const aux = { ...data.data(), key: data.id };

          setNome(aux.nome);
          setFileUrl(aux.fileUrl);
          setDescription(aux.description);
          setFileName(aux.fileName);

          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, []);

  const messageSuccess = (text: string) => {
    setMessage(text);
    setSuccessMessage(true);
    setOpenMessage(true);
    setTimeout(() => {
      setOpenMessage(false);
      history.push('/library/list');
    }, 2000);
    setLoading(false);
  };

  const messageErro = (text: string) => {
    setMessage(text);
    setSuccessMessage(false);
    setOpenMessage(true);
    setTimeout(() => {
      setOpenMessage(false);
    }, 2000);
    setLoading(false);
  };

  const handleSubmit = () => {
    if (nome === '' || !file) {
      addToast({
        type: 'error',
        title: 'Notificação!',
        description: 'Preencha todos os campos!',
      });
      return;
    }

    const auxFileName = `${file.name}`;
    const uploadTask = storage
      .ref(`/${LIVROS_COLLECTION}/${auxFileName}`)
      .put(file);

    uploadTask.on(
      'state_changed',
      snapShot => {
        console.log('snapShot');
        console.log(snapShot);
      },
      err => {
        setMessage('Erro ao cadastrar arquivo');
        setSuccessMessage(false);
        setOpenMessage(true);
        setTimeout(() => {
          setOpenMessage(false);
        }, 2000);
      },
      () => {
        storage
          .ref(LIVROS_COLLECTION)
          .child(auxFileName)
          .getDownloadURL()
          .then(fireBaseUrl => {
            firestore
              .collection(LIVROS_COLLECTION)
              .add({
                fileName: auxFileName,
                fileUrl: fireBaseUrl,
                description,
                nome,
              })
              .then(() => messageSuccess('Arquivo cadastrado com sucesso'))
              .catch(() => messageErro('Erro ao cadastrar arquivo'));
          });
      },
    );

    setLoading(true);
  };

  const handleDelete = () => {
    if (ID) {
      setLoading(true);
      firestore
        .collection(LIVROS_COLLECTION)
        .doc(ID)
        .delete()
        .then(() => {
          storage
            .ref(LIVROS_COLLECTION)
            .child(fileName)
            .delete()
            .then(() => messageSuccess('Palavra excluida com sucesso'))
            .catch(() => messageErro('Erro ao excluir palavra'));
        })
        .catch(() => messageErro('Erro ao excluir palavra'));
    }
  };

  const handlePdf = (e: any) => {
    const auxFile = e.target.files[0];
    setFile(auxFile);
  };

  return (
    <>
      <Container>
        <Path>
          {`Dashboard / Biblioteca / ${ID ? 'Material' : 'Novo Material'}`}
        </Path>
        <HeaderPages
          title={`${ID ? 'Material' : 'Novo Material'}`}
          urlBack="/library/list"
        />

        <Loading loading={loading}>
          <FormContainer>
            <FormGroup>
              <h2>NOME*</h2>
              <Input
                id="nameMaterialDidatico"
                type="text"
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <h2>DESCRIÇÃO</h2>
              <textarea
                id="nameMaterialDidatico"
                value={description}
                onChange={e => setDescription(e.target.value)}
                rows={5}
              />
            </FormGroup>

            {!fileUrl && (
              <FormGroup>
                <p>Selecione o arquivo em PDF</p>
                <input type="file" onChange={e => handlePdf(e)} />
              </FormGroup>
            )}

            {fileUrl && (
              <FormGroup>
                <h2>CAPA DO MATERIAL</h2>
                <ImageWrapper
                  onClick={() => {
                    window.open(fileUrl);
                  }}
                >
                  <embed
                    width="150"
                    height="200"
                    src={fileUrl}
                    type="application/pdf"
                  />
                </ImageWrapper>
              </FormGroup>
            )}

            <div className="wrappperButton">
              {ID && <Button onClick={handleDelete}>Excluir Arquivo</Button>}
              {!ID && (
                <Button onClick={handleSubmit} disabled={!!ID}>
                  {ID ? 'Atualizar Arquivo' : 'Salvar Arquivo'}
                </Button>
              )}
            </div>
          </FormContainer>
        </Loading>
      </Container>

      <Message open={openMessage} message={message} success={successMessage} />
    </>
  );
};

export default Form;
