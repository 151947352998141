import React from 'react';

import { Container, Block, Spinner } from './styles';

type LoadingProps = {
  loading: boolean;
};

const Loading: React.FC<LoadingProps> = ({ loading, children }) => {
  return (
    <Container>
      <Block loading={loading}>
        <Spinner />
      </Block>
      {children}
    </Container>
  );
};

export default Loading;
