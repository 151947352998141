import styled from 'styled-components';

export const Container = styled.div``;

export const Path = styled.div`
  margin-bottom: 25px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 25px;
  padding: 30px;

  border: solid 1px ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  .emailFone {
    display: flex;
  }

  .wrappperButton {
    display: flex;
    justify-content: flex-end;

    margin-top: 55px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 15px;

  :last-child {
    margin-left: 30px;
  }

  h2 {
    font-weight: 100;
    font-size: 17px;
  }
`;
