import React from 'react';

import containerSpotfy from '@assets/images/containerSpotfy.png';
import containerYtb from '@assets/images/containerYtb.png';

import { Container } from './styles';

const Content: React.FC = () => {
  return (
    <Container>
      <h1>Acompanhe</h1>
      <h1>nossos conteúdos</h1>
      <h3>PREGAÇÕES - DEVOCIONAIS - PLAYLIST</h3>
      <div>
        <a href="https://open.spotify.com/show/6D8s0Tz20PLFaCvctfwHxk?si=3eMiZEJZRHGUyuW1eEm7yg">
          <img src={containerSpotfy} alt="spotfy" />
        </a>
        <a href="https://www.youtube.com/c/IgrejaBatista%C3%81gape-PSO">
          <img src={containerYtb} alt="youtube" />
        </a>
      </div>
    </Container>
  );
};

export default Content;
