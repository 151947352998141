import React, { useEffect, useState, useMemo } from 'react';
import Slider from 'react-slick';

import Header from '@components/site/header';
import AboutUs from '@components/site/about-us';

import { firestore } from '@services/firebaseConfig';
import { CONTEUDOS_STORAGE } from '@utils/firebaseCollections';

import { Container, Wrapper, CardTest } from './styles';

const PalavraCelula: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [palavrasBatismo, setPalavrasBatismo] = useState<Array<any> | []>([]);
  const [palavrasCelula, setPalavrasCelula] = useState<Array<any> | []>([]);
  const [palavrasCelebracao, setPalavrasCelebracao] = useState<Array<any> | []>(
    [],
  );

  useEffect(() => {
    setLoading(true);
    const conteudosFirestore = firestore.collection(CONTEUDOS_STORAGE);
    conteudosFirestore
      .get()
      .then((data: any) => {
        if (data.docs.length) {
          const auxPalavrasBatismo: Array<any> = [];
          const auxPalavrasCelula: Array<any> = [];
          const auxPalavrasCelebracao: Array<any> = [];
          data.forEach((doc: any) => {
            const temp = { ...doc.data(), key: doc.id };
            if (temp.type === 'CURSO_BATISMO') {
              auxPalavrasBatismo.push(temp);
            } else if (temp.type === 'PALAVRA_CELULA') {
              auxPalavrasCelula.push(temp);
            } else if (temp.type === 'CELEBRACAO_DOMINGO') {
              auxPalavrasCelebracao.push(temp);
            }
          });

          setPalavrasBatismo(auxPalavrasBatismo);
          setPalavrasCelula(auxPalavrasCelula);
          setPalavrasCelebracao(auxPalavrasCelebracao);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const renderPalavrasBatismo = useMemo(() => {
    const aux: Array<any> = [];

    if (palavrasBatismo.length < 4) {
      for (let index = palavrasBatismo.length; index < 4; index += 1) {
        aux.push(<div />);
      }
    }

    palavrasBatismo.forEach((item: any) => {
      aux.push(
        <CardTest
          key={item.key}
          onClick={() => {
            window.open(item.fileUrl);
          }}
        >
          <div className="iconAndLabel">
            <h1>{item.name}</h1>
          </div>
        </CardTest>,
      );
    });

    return aux.reverse();
  }, [palavrasBatismo]);

  const renderPalavrasCelulas = useMemo(() => {
    const aux: Array<any> = [];

    if (palavrasCelula.length < 4) {
      for (let index = palavrasCelula.length; index < 4; index += 1) {
        aux.push(<div />);
      }
    }

    palavrasCelula.forEach((item: any) => {
      aux.push(
        <CardTest
          key={item.key}
          onClick={() => {
            window.open(item.fileUrl);
          }}
        >
          <div className="iconAndLabel">
            <h1>{item.name}</h1>
          </div>
        </CardTest>,
      );
    });

    return aux.reverse();
  }, [palavrasCelula]);

  const renderPalavrasCelebracao = useMemo(() => {
    const aux: Array<any> = [];

    if (palavrasCelebracao.length < 4) {
      for (let index = palavrasCelebracao.length; index < 4; index += 1) {
        aux.push(<div />);
      }
    }

    palavrasCelebracao.forEach((item: any) => {
      aux.push(
        <CardTest
          key={item.key}
          onClick={() => {
            window.open(item.fileUrl);
          }}
        >
          <div className="iconAndLabel">
            <h1>{item.name}</h1>
          </div>
        </CardTest>,
      );
    });

    if (aux.length < 4) {
      for (let index = aux.length; index < 4; index += 1) {
        aux.push(<div />);
      }
    }

    return aux;
  }, [palavrasCelebracao]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Header menuType="CONTEUDOS" isBlack />

      <Wrapper>
        {palavrasBatismo.length > 0 && (
          <div>
            <h1>Sala de Batismo</h1>
            <Slider {...settings}>{renderPalavrasBatismo}</Slider>
          </div>
        )}

        {palavrasCelula.length > 0 && (
          <div>
            <h1>Estudos Pequenos Grupos</h1>
            <Slider {...settings}>{renderPalavrasCelulas}</Slider>
          </div>
        )}

        {palavrasCelebracao.length > 0 && (
          <div>
            <h1>Palavras das Celebrações</h1>
            <Slider {...settings}>{renderPalavrasCelebracao}</Slider>
          </div>
        )}
      </Wrapper>

      <div className="footer">
        <AboutUs />
      </div>
    </Container>
  );
};

export default PalavraCelula;
