import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getItems } from '@services/commons';
import { LIVROS_COLLECTION } from '@utils/firebaseCollections';

import HeaderPages from '@components/common/headerPages';
import Loading from '@components/common/loading';
import Table from '@components/common/table';

import edit from '@assets/images/icons/edit.svg';

import { Container, Path } from './styles';

const List: React.FC = () => {
  const history = useHistory();

  const LIMIT_ITEMS = 15;

  const [loading, setLoading] = useState(false);
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [livros, setLivros] = useState<Array<any> | []>([]);

  useEffect(() => {
    setLoading(true);
    getItems(LIVROS_COLLECTION, 'nome').then((data: any) => {
      const map: Array<any> = [];
      if (data.docs.length) {
        data.forEach((doc: any) => {
          const aux = { ...doc.data(), key: doc.id };
          map.push(aux);
        });
        setStartAt(data.docs[0]);
        setEndAt(data.docs[data.docs.length - 1]);
      }
      setLivros(map);
      setLoading(false);
    });
  }, []);

  const getDataTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      aux.push([
        item.nome,
        <a href={`/library/form?id=${item.key}`}>
          <img src={edit} alt="Botão de editar" />
        </a>,
      ]);
    });
    return aux;
  };

  return (
    <Container>
      <Path>Dashboard / Biblioteca</Path>
      <HeaderPages
        title="Biblioteca"
        urlBack="/dashboard"
        buttonName="Novo Material"
        buttonClick={() => history.push('/library/form')}
      />

      <Loading loading={loading}>
        <Table data={getDataTable(livros)} columns={['Nome']} withAction />
      </Loading>
    </Container>
  );
};

export default List;
