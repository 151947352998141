import { auth } from '@services/firebaseConfig';

export const createUser = (email: string) => {
  return new Promise((resolve, reject) => {
    auth
      .createUserWithEmailAndPassword(email, '123456')
      .then(response => {
        if (response.user) {
          const { uid } = response.user;
          resolve(uid);
        }
      })
      .catch(error => reject(error));
  });
};
