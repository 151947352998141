import { css } from 'styled-components';

export const bahnschrift = css`
  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 1.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 10.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 11.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 12.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 13.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 14.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 2.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 3.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 4.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 5.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 6.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 7.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 8.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT 9.woff') format('woff');
  }

  @font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('BAHNSCHRIFT.woff') format('woff');
  }
`;
