import React from 'react';
import { useHistory } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Images
import rdl from '@assets/images/ministerys/rdl.jpg';
import celulas from '@assets/images/ministerys/celulas.jpg';
import cfma from '@assets/images/ministerys/cfma.png';
import miniserioHope from '@assets/images/ministerys/miniserioHope.png';
import acolhimento from '@assets/images/ministerys/acolhimento.png';
import agapeKids from '@assets/images/ministerys/agapeKids.png';
import ebd from '@assets/images/ministerys/ebd.png';
import centurioes from '@assets/images/ministerys/centurioes.jpeg';
import envagelismoAvancar from '@assets/images/ministerys/envagelismoAvancar.jpeg';
import agapeMidia from '@assets/images/ministerys/agapeMidia.jpeg';
import diaconal from '@assets/images/ministerys/diaconal.jpeg';
import louvor from '@assets/images/ministerys/louvor.png';

import { Container, ContainerMobile, Item, ContainerHover } from './styles';

const data = [
  { url: agapeMidia, title: 'Ministério de Mídia' },
  { url: diaconal, title: 'Ministério diaconal' },
  { url: envagelismoAvancar, title: 'Ministério Evangelismo Avançar' },

  { url: ebd, title: 'Escola Biblica Discipuladora' },
  { url: rdl, title: 'RDL' },
  { url: louvor, title: 'Ministério de Louvor' },

  { url: centurioes, title: 'Centirões' },
  { url: cfma, title: 'Centro de formação ministerial ágape' },
  { url: miniserioHope, title: 'Ministério Hope' },

  { url: celulas, title: 'Pequenos Grupos', urlPath: '/ministerios/celulas' },
  { url: agapeKids, title: 'Ministério Infantil' },
  { url: acolhimento, title: 'Ministério de acolhimento' },
];

// Centuriões ✅
// Hope ✅
// Ágape Kids ✅
// Ministério de Mídia ✅
// Ministério Avançar ✅
// Louvor ✅
// Ministério Diaconal ✅
// Escola Bíblica Discipuladora ✅
// Celulas ✅
// Ministério de Acolhimento ✅
// CFMA ✅
// RDL

const Ministery: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Container>
        {data.map(item => (
          <Item
            key={`ministerys_${item.title}`}
            onClick={() => {
              if (item.urlPath) history.push(item.urlPath);
            }}
          >
            <img src={item.url} alt="Avatar" />
            <ContainerHover>
              <h1>{item.title}</h1>
            </ContainerHover>
          </Item>
        ))}
      </Container>

      <ContainerMobile>
        <Carousel
          showThumbs={false}
          interval={2500}
          infiniteLoop
          autoPlay
          showStatus={false}
        >
          {data.map(item => (
            <Item
              key={`ministerys_${item.title}`}
              onClick={() => {
                if (item.urlPath) history.push(item.urlPath);
              }}
            >
              <img src={item.url} alt="Avatar" />
              <ContainerHover>
                <h1>{item.title}</h1>
              </ContainerHover>
            </Item>
          ))}
        </Carousel>
      </ContainerMobile>
    </>
  );
};

export default Ministery;
