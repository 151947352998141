import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@hooks/auth';

import Loading from '@components/common/loading';
import HeaderPages from '@components/common/headerPages';

import { getItemById, getCollectionByItem } from '@services/commons';
import { USERS_COLLECTION, CURSO_COLLECTION } from '@utils/firebaseCollections';

import { Container, WrapperCards, CardTest } from './styles';

const Class: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [cursos, setCursos] = useState<Array<any> | []>([]);

  useEffect(() => {
    setLoading(true);
    getCollectionByItem(USERS_COLLECTION, user.uid, CURSO_COLLECTION).then(
      (data: any) => {
        const map: Array<any> = [];
        if (data.docs.length) {
          data.forEach((doc: any) => {
            getItemById(CURSO_COLLECTION, doc.id).then((curso: any) => {
              const aux = { ...doc.data(), ...curso.data(), uid: doc.id };
              map.push(aux);
              setCursos(map);
            });
          });
        }
        setLoading(false);
      },
    );
  }, []);

  const goToClass = (uid: string) => {
    history.push(`/curso/dash?id=${uid}`);
  };

  const renderCursos = () => {
    const aux: Array<any> = [];

    cursos.forEach((item: any) => {
      aux.push(
        <CardTest key={item.uid} onClick={() => goToClass(item.uid)}>
          <div className="iconAndLabel">
            <h1>{item.nome}</h1>
          </div>
        </CardTest>,
      );
    });

    return aux;
  };

  return (
    <Loading loading={loading}>
      <Container>
        <HeaderPages title="Área do Aluno" />

        <h3>Cursos</h3>
        <WrapperCards>{renderCursos()}</WrapperCards>
      </Container>
    </Loading>
  );
};

export default Class;
