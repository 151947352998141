import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding-top: 64px;

  .footer {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 0px 180px;
  padding-top: 80px;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const CityCart = styled.div`
  width: 100%;
  height: 100%;

  background: lightgray;
  border-radius: 4px;

  padding: 0px 30px;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  background: #222;
  color: white;
  width: fit-content;
  padding: 5px 20px;
  margin-bottom: 30px;
`;

export const Celula = styled.div`
  transition: all 0.8s;

  button {
    margin: 10px 0px;

    width: 100%;
    padding: 10px;

    background: white;

    border: none;

    text-align: start;
    text-transform: uppercase;

    :hover {
      opacity: 0.8;
    }
  }

  ul {
    list-style: none;
    margin-top: -5px;
    margin-bottom: 10px;
    margin-left: 8px;

    li {
      display: flex;
      align-items: center;

      margin: 8px 0px;
      font-size: 15px;

      h1 {
        font-size: 15px;
        margin-right: 5px;
      }
    }

    transition: 0.4s;
  }
`;
