import React, { useCallback, useEffect, useState } from 'react';
import Header from '@components/site/header';
import AboutUs from '@components/site/about-us';

// import CELULAS from '@assets/celulas';
import { firestore } from '@services/firebaseConfig';
import { CELULAS } from '@utils/firebaseCollections';

import { Container, Wrapper, CityCart, Title, Celula } from './styles';

const CelulasPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState('');
  const [celulasParaiso, setCelulasParaiso] = useState<any[]>([]);
  const [celulasCatalao, setCelulasCatalao] = useState<any[]>([]);

  useEffect(() => {
    firestore
      .collection(CELULAS)
      .orderBy('Cidade')
      .get()
      .then((data: any) => {
        if (data.docs.length) {
          const paraiso: any[] = [];
          const catalao: any[] = [];
          data.forEach((doc: any) => {
            const temp = { ...doc.data(), key: doc.id };
            const semAcento = temp.Cidade.normalize('NFD').replace(
              /[\u0300-\u036f]/g,
              '',
            );
            if (semAcento.toLowerCase() === 'paraiso do tocantins')
              paraiso.push(temp);
            if (semAcento.toLowerCase() === 'catalao') catalao.push(temp);
          });
          console.log('paraiso');
          console.log(paraiso);
          setCelulasParaiso(paraiso);
          setCelulasCatalao(catalao);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  console.log('open');
  console.log(open);

  const getCelulasPorDiaSemana = useCallback(
    (arrayCelulas: any[], cidade: string) => {
      const diasDaSemana: any = {
        segunda: [],
        terca: [],
        quarta: [],
        quinta: [],
        sexta: [],
        sabado: [],
        domingo: [],
      };

      arrayCelulas.forEach(celula => {
        const semAcento = celula.Dia.normalize('NFD').replace(
          /[\u0300-\u036f]/g,
          '',
        );
        const auxDiaSemana = semAcento.split('-feira')[0];
        diasDaSemana[auxDiaSemana.toLowerCase()].push(celula);
      });

      return (
        <Celula>
          {Object.keys(diasDaSemana).map(
            dia =>
              diasDaSemana[dia].length > 0 && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (open === `${cidade}-${dia}`) setOpen('');
                      else setOpen(`${cidade}-${dia}`);
                    }}
                  >
                    {diasDaSemana[dia][0].Dia}
                  </button>
                  {open === `${cidade}-${dia}` && (
                    <ul>
                      {diasDaSemana[dia].map((auxCelula: any) => (
                        <li>
                          <h1>{`${auxCelula.Horario}h `}</h1>
                          <p>
                            {` - Pequeno grupo ${auxCelula.Nome}, `}
                            {`Endereço: ${auxCelula.Logradouro} ${auxCelula.Numero}, ${auxCelula.Bairro}`}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ),
          )}
        </Celula>
      );
    },
    [open],
  );

  const getCelulasParaiso = useCallback(() => {
    return getCelulasPorDiaSemana(celulasParaiso, 'paraiso');
  }, [celulasParaiso, getCelulasPorDiaSemana]);

  const getCelulasCatalao = useCallback(() => {
    return getCelulasPorDiaSemana(celulasCatalao, 'catalao');
  }, [celulasCatalao, getCelulasPorDiaSemana]);

  return (
    <>
      <Header menuType="MINISTERIOS_CELULAS" isBlack />
      <Container>
        <Wrapper>
          <CityCart>
            <Title>Paraíso do Tocantins</Title>
            {getCelulasParaiso()}
          </CityCart>
          <CityCart>
            <Title>Catalão</Title>
            {getCelulasCatalao()}
          </CityCart>
        </Wrapper>
        <div className="footer">
          <AboutUs />
        </div>
      </Container>
    </>
  );
};

export default CelulasPage;
