import styled from 'styled-components';

// interface IButtonProps {
//   variant: ;
// }

const Button = styled.button`
  padding: 10px 30px;

  border: none;
  border-radius: 25px;

  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};

  &.secondary {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &.tertiary {
    color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
  }

  :hover {
    opacity: 0.95;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.secondary};
    opacity: 0.95;
    cursor: default;
  }
`;

export default Button;
