import styled from 'styled-components';

interface IContainerProps {
  whiteBackground: boolean;
}

export const Container = styled('div')<IContainerProps>`
  display: grid;
  width: 100%;
  grid-template-areas: 'auto auto';

  position: fixed;

  z-index: 100;
  top: 0;
  padding: 10px 200px;

  background-color: ${({ whiteBackground }) =>
    whiteBackground ? 'white' : 'none'};
  color: ${({ whiteBackground }) => (whiteBackground ? 'black' : 'white')};
  box-shadow: ${({ whiteBackground }) =>
    whiteBackground && '0px 1px 10px lightgrey'};

  transition: 0.5s;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 15px 100px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.mobile}) {
    padding: 15px 20px;
  }
`;

export const Logo = styled.div`
  img {
    height: 40px;
    width: auto;
  }

  cursor: pointer;
`;

export const Menu = styled.div``;
