import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    padding: 0px 20px;
  }
`;

export const Path = styled.div`
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    flex-direction: column;
  }
`;

export const Video = styled.div`
  width: 75%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 30px;
    color: ${({ theme }) => theme.colors.darkGray};

    width: 100%;
    height: 450px;

    background-color: ${({ theme }) => theme.colors.backgroundHeader};

    transition: 0.5s;
    :hover {
      font-size: 35px;
    }
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    width: 100%;
  }
`;

export const AulasList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
  padding: 0px 20px;

  div {
    width: 100%;
    margin-top: 15px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.tablet}) {
    width: 100%;
  }
`;

export const LabelAula = styled.div`
  font-weight: 100;

  transition: 0.1s;
  cursor: pointer;

  &.selected {
    font-weight: bold;
  }
  :hover {
    font-weight: bold;
  }
`;
