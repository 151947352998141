import styled from 'styled-components';

interface IHamburgerLineProps {
  whiteBackground: boolean;
  activeMobileMenu: boolean;
}

export const Container = styled.div``;

export const HamburguerLine = styled('div')<IHamburgerLineProps>`
  display: block;
  height: 7px;
  width: 43px;

  background: ${({ whiteBackground }) =>
    whiteBackground ? '#3a3738' : 'white'};
  border-radius: 9px;

  opacity: 1;
  left: 0;

  transform-origin: right center;
  transition: 0.5s ease-in-out;

  transform: rotate(0deg);

  ${({ activeMobileMenu }) =>
    activeMobileMenu &&
    `
      &.rotateTop {
        transform: rotate(-45deg);
      }

      &.rotateBottom {
        transform: rotate(45deg);
      }

      &.rotateMiddle {
        width: 0%;
        opacity: 0;
        transition: 0.1s;
      }
  `}
`;
