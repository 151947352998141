export const LIVROS_COLLECTION = 'livros';
export const USERS_COLLECTION = 'users';
export const CELULAS = 'celulas';

export const CURSO_COLLECTION = 'cursos';
export const CURSO_MATERIAS_COLLECTION = 'materias';
export const CURSO_PERIODOS_COLLECTION = 'periodos';
export const CURSO_ALUNOS_COLLECTION = 'alunos';

export const AULAS_COLLECTION = 'aulas';

export const PALAVRAS_CELULAS_STORAGE = 'palavrasCelula';
export const CONTEUDOS_STORAGE = 'conteudos';
