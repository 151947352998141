import { firestore } from './firebaseConfig';

export const getItems: any = (path: string, orderBy?: string) => {
  if (orderBy) return firestore.collection(path).orderBy(orderBy).get();
  return firestore.collection(path).get();
};

export const getItemById: any = (path: string, key: string) => {
  return firestore.collection(path).doc(key).get();
};

export const getCollectionByItem: any = (
  path: string,
  key: string,
  collection: string,
) => {
  return firestore.collection(path).doc(key).collection(collection).get();
};

export const createItem: any = (path: string, item: any) => {
  return firestore.collection(path).add(item);
};

export const setNewItem: any = (path: string, key: string, item: any) => {
  return firestore.collection(path).doc(key).set(item);
};

export const updateItem: any = (path: string, key: string, item: any) => {
  const ref = firestore.collection(path).doc(key);
  return ref.update(item);
};

export const deleteItem: any = (path: string, key: string) => {
  const ref = firestore.collection(path).doc(key);
  return ref.delete();
};
