import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Table from '@components/common/table';
import Loading from '@components/common/loading';
import HeaderPages from '@components/common/headerPages';

import edit from '@assets/images/icons/edit.svg';

import { AULAS_COLLECTION } from '@utils/firebaseCollections';
import { firestore } from '@services/firebaseConfig';

import { Container, Path } from './styles';

const List: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [aulas, setAulas] = useState<Array<any> | []>([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setLoading(true);

    firestore
      .collection(AULAS_COLLECTION)
      .orderBy('materia_nome')
      .get()
      .then((data: any) => {
        if (data.docs.length) {
          const aux: Array<any> = [];
          data.forEach((doc: any) => {
            const temp = { ...doc.data(), key: doc.id };
            aux.push(temp);
          });

          // aux.sort((a, b) => a.ordem - b.ordem);
          setAulas(aux);
        }
        setLoading(false);
      });
  }, []);

  const getDataTable = (object: any) => {
    const aux: any = [];
    object.forEach((item: any) => {
      aux.push([
        `Aula ${item.ordem}`,
        item.materia_nome,
        item.curso_nome,
        <a href={`/aulas/form?id=${item.key}`}>
          <img src={edit} alt="Botão de editar" />
        </a>,
      ]);
    });
    return aux;
  };

  return (
    <>
      <Container>
        <Path>Dashboard / Aulas</Path>
        <HeaderPages
          title="Aulas"
          urlBack="/dashboard"
          buttonName="Nova Aula"
          buttonClick={() => history.push('/aulas/form')}
        />

        <Loading loading={loading}>
          <Table
            data={getDataTable(aulas)}
            columns={['Aula', 'Matéria', 'Curso']}
            withAction
          />
        </Loading>
      </Container>
    </>
  );
};

export default List;
