import styled from 'styled-components';

const Input = styled.input`
  margin: 5px 0px;
  padding: 0px 10px;

  width: 100%;
  height: 35px;

  font-size: 17px;

  border: 1px solid ${({ theme }) => theme.colors.secondary};
`;

export default Input;
